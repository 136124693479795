import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import ChipCustom from "../ChipCustom/ChipCustom";
import "./ChipsArray.scss";
import { Box, Tabs, Typography } from "@material-ui/core";
//import TagFacesIcon from "@material-ui/icons/TagFaces";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));


const ChipsArray = ({ chipsData, chipManage }) => {
  
  const chipData = chipsData;
  console.log(chipsData)
  let handleClickChip= (dataChip) =>{
    chipManage(dataChip);

  }
  return ( 
    <>
      {chipData && (
        <div className="size-tab">
          <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example" 
          value={(0)}
          
        >
          {chipData.map((data) => <ChipCustom dataToChip={data} chipClick={handleClickChip} key={data.key}/>)}
        </Tabs>
          
        </div>
        
      )}
    </>
  );
};

export default ChipsArray;
