import React, { useState } from "react";
import "./FranchiseItem.scss";
import { FormattedMessage } from "react-intl";

export default function FranchiseItem(props) {

  // ----------------------------------------------------
  // ------------- Variables & Constants ----------------
  // ----------------------------------------------------

  const {franchiseImage, franchiseName, franchiseAddress} = props

  // ----------------------------------------------------
  // -------------------- Render ------------------------
  // ----------------------------------------------------

  return (
    <div className="franchise-item-container">
        <img src={franchiseImage} alt="franchise" className="franchise-item-image" />

        <div className="text-container">
            <div className="franchise-item-name">{franchiseName}</div> 
            <div className="franchise-item-address">{franchiseAddress}</div>
        </div>

    </div>
  );
}
