import {
    URL_HELP,
  requestOptionsGet,
  requestOptionsPost,
  requestOptionsPut,
  getJSON,
  URL_DOMAIN_SUPERADMIN,
} from "./globals";

const eInvoiceSalesExcluded = async (jsonBody) => {
  requestOptionsPost.body = jsonBody;
  return fetch(
    URL_HELP + "invoice/electronic-invoice/sales-excluded",
    requestOptionsPost
  ).then(getJSON);
};

const createEInvoiceForCustomer = async (jsonBody) => {
  requestOptionsPost.body = jsonBody;
  return fetch(
    URL_HELP + "invoice/electronic-invoice/electronic-invoice-consumer",
    requestOptionsPost
  ).then(getJSON);
};

const createMassiveEInvoice = async (jsonBody) => {
  requestOptionsPost.body = jsonBody;
  return fetch(
    URL_HELP + "invoice/electronic-invoice/electronic-invoice-organization",
    requestOptionsPost
  ).then(getJSON);
};

const previewMassiveEInvoice = async (jsonObj) => {
  const { start_date, end_date, fk_organization, excluded_sale_points } = jsonObj;
  return fetch(
    URL_HELP +
      "invoice/electronic-invoice/invoice-summary-excluded?" +
      `fk_organization=${fk_organization}` +
      `&start_date=${start_date}` +
      `&end_date=${end_date}` +
      `&excluded_sale_points=[${excluded_sale_points}]`,
    requestOptionsGet
  )
};


const eInvoiceSummaryAllOrganization = async (fkOrganization) => {
  return fetch(
    URL_HELP +
      "invoice/electronic-invoice/last-invoice-summary?fk_organization=" +
      fkOrganization,
    requestOptionsGet
  ).then(getJSON);
};

export {
  createEInvoiceForCustomer,
  createMassiveEInvoice,
  eInvoiceSummaryAllOrganization,
  previewMassiveEInvoice,
  eInvoiceSalesExcluded
};
