import LogoKuruah from "../../assets/img/Kuruah-side-logo.svg";
import { TextField, Select, MenuItem } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import "./Subscribe.scss";

const Subscribe = () => {
  const checkKeyDown = (event) => {
    if (event.code === "Enter") event.preventDefault();
  };

  return (
    <main>
      <div className="purple-header">
        <img className="ml-5" src={LogoKuruah} alt="" />
      </div>
      <div className="subscribe-content">
        <h1 className="login-title">¿Quieres probar Kurúah?</h1>
        <p>
          Regístrate en nuestra plataforma y prueba las aplicaciones web y
          móviles para controlar tu negocio a distancia
        </p>
        <h5 className="mb-4">
          <FormattedMessage id="userDataTitle" />
        </h5>
        <form
          onSubmit=""
          className="row g-3"
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <div className="row">
            <div className="col-6">
              <div className="mb-4">
                <TextField
                  required
                  className="form-control"
                  id="standard-basic"
                  label={<FormattedMessage id="nameLbl" />}
                  // value={operator.username?operator.username:""}
                  name="name"
                  // onChange={handleInputChangeInner}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="mb-4">
                <TextField
                  required
                  className="form-control"
                  id="standard-basic"
                  label={<FormattedMessage id="emailLbl" />}
                  // value={operator.email?operator.email:""}
                  name="email"
                  // onChange={handleInputChangeInner}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-4">
                <TextField
                  required
                  className="form-control"
                  id="standard-basic"
                  label={<FormattedMessage id="phoneLbl" />}
                  // value={operator.email?operator.email:""}
                  name="phone"
                  // onChange={handleInputChangeInner}
                />
              </div>
            </div>
          </div>
          <h5 className="mb-4">
            <FormattedMessage id="businessDataTitle" />
          </h5>
          <div className="row">
            <div className="col-6">
              <div className="mb-4">
                <TextField
                  required
                  className="form-control"
                  id="standard-basic"
                  label={<FormattedMessage id="businessNameLbl" />}
                  // value={operator.username?operator.username:""}
                  name="name"
                  // onChange={handleInputChangeInner}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="mb-4">
                <TextField
                  required
                  className="form-control"
                  id="standard-basic"
                  label={<FormattedMessage id="cityLbl" />}
                  // value={operator.email?operator.email:""}
                  name="email"
                  // onChange={handleInputChangeInner}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-4">
                <TextField
                  required
                  className="form-control"
                  id="standard-basic"
                  label={<FormattedMessage id="phoneLbl" />}
                  // value={operator.email?operator.email:""}
                  name="phone"
                  // onChange={handleInputChangeInner}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};

export default Subscribe;
