import React, { useState, useEffect } from "react";
import "../../../index.scss";
import "./ProductIngredientPage.scss";
import {
  getIngredientsForProduct,
  getIngredientsInProduct,
  postNewIngredientToProduct,
  postOldIngredientToProduct,
  deleteIngredientsInProduct
} from "../../../services/utilsIngredient/utilsIndredient";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import editIcon from "../../../assets/icons/baseline-edit-purple-24px.svg";
import deleteIcon from "../../../assets/icons/baseline-close-24px.svg";

const filter = createFilterOptions();

const ProductIngredientPage = (ingredients) => {
  const [ingredientNew, setIngredientNew] = useState({});

  const [isNewIngredient, setIsNewIngredient] = useState(true);

  const [ingredientList, setIngredientList] = useState([]);

  const [availableIngredients, setAvailableIngredients] = useState([]);

  const [currentIngredinet, setCurrentIngredient] = useState(" ");

  const [disableUnit, setDisableUnit] = useState(false);

  const fetchData = async () => {
    let productId = localStorage.getItem("fk_product");
    //setIngredientList(ingredients["ingredients"]);

    getIngredientsForProduct(productId).then((data) => {
      setAvailableIngredients(data["ingredients"]);
    });

    getIngredientsInProduct(productId).then((data) => {
      setIngredientList(data["ingredients"]);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChangeInner = (event) => {
    setIngredientNew({
      ...ingredientNew,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeOfIngredient = (value) => {
    //SI ya existia la categoria
    if (value) {
      if (value.id) {
        setIngredientNew({
          ...ingredientNew,
          fk_ingredient: value.id,
          unit: value.unit,
        });
        setIsNewIngredient(false);
        setDisableUnit(true);
      } else {
        setIngredientNew({
          ...ingredientNew,
          fk_ingredient: "",
          name: value.inputValue,
        });
        setIsNewIngredient(true);
        setDisableUnit(false);
      }
    } else {
      setIngredientNew({});
      setDisableUnit(true);
      // setProduct({ ...product, fk_franchise_type: "" });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let productId = localStorage.getItem("fk_product");

    let formDataBody = new FormData();
    console.log("VARIABLE BOOLEAN", isNewIngredient);
    if (isNewIngredient) {
      //Es un nuevo ingrediente

      formDataBody.append("fk_product", productId);
      formDataBody.append("name", ingredientNew["name"]);
      formDataBody.append("quantity", ingredientNew["quantity"]);
      formDataBody.append("unit", ingredientNew["unit"]);
      postNewIngredientToProduct(formDataBody).then((data) => {
        console.log("Se creo un NEW ingredient", data);
      });
    } else {
      //Es un ingrediente antigo

      formDataBody.append("fk_product", productId);
      formDataBody.append("fk_ingredient", ingredientNew["fk_ingredient"]);
      formDataBody.append("quantity", ingredientNew["quantity"]);
      postOldIngredientToProduct(formDataBody).then((data) => {
        console.log("Se creo un old ingredient", data);
      });
      setDisableUnit(false);
      //
    }
    setCurrentIngredient("");
    setIngredientNew({});
    fetchData();
  };

  function deleteIngredient(ingredientToDelete){
    let productId = localStorage.getItem("fk_product");
    let ingredientId = ingredientToDelete.id;
    console.log("Vamos a eliminar el siguiente ingrediente", ingredientToDelete,productId);
    deleteIngredientsInProduct(productId, ingredientId).then((data)=>{
      fetchData();
    })
    
  }

  let goIngredient = (ingredientId) =>{
    localStorage.setItem("fk_ingredient",ingredientId);
  }
  
  return (
    <div>
      <form onSubmit={handleSubmit} action="">
        <div className="row">
          <div className="col-5">
            <Autocomplete
              value={currentIngredinet ? currentIngredinet : ""}
              onChange={(event, newValue) => {
                handleChangeOfIngredient(newValue);
                if (typeof newValue == "string") {
                  setCurrentIngredient({
                    title: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  setCurrentIngredient({
                    title: newValue.inputValue,
                  });
                } else {
                  setCurrentIngredient(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Add "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="category-input"
              options={availableIngredients}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }

                if (option.title) {
                  return option.title;
                }

                // Regular option
                return option.name;
              }}
              renderOption={(option) => (
                <React.Fragment>
                  <div>
                    <p className="option-ingredient-info name">{option.name}</p>
                    <p className="option-ingredient-info unit">
                      {option.unit ? option.unit : ""}
                    </p>
                  </div>
                </React.Fragment>
              )}
              freeSolo
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label={<FormattedMessage id="ingredientLbl" />}
                />
              )}
            />
          </div>
          <div className="col-2">
            <TextField
              value={ingredientNew.quantity ? ingredientNew.quantity : " "}
              onChange={handleInputChangeInner}
              name="quantity"
              required
              label={<FormattedMessage id="quantityLbl" />}
            />
          </div>
          <div className="col-2">
            <TextField
            disabled={disableUnit}
              required
              onChange={handleInputChangeInner}
              name="unit"
              value={ingredientNew.unit ? ingredientNew.unit : " "}
              label={<FormattedMessage id="unityLbl" />}
            />
          </div>
          <div className="col-3" id="add-ingredient-button">
            <button className="btn btn-primary m-2" type="submit">
              <FormattedMessage id="addIngredientLbl" />
            </button>
          </div>
        </div>
      </form>

      <div>
        {ingredientList &&
          ingredientList.map((ingredient, i) => (
            <div className="row ingredient-item-container">
              <div className="col">
                <div className="row">
                  <p className="ingredient-name no-margin">{ingredient.name}</p>
                  <p className="ingredient-quantity no-margin">
                    {ingredient.quantity} {ingredient.unit}
                  </p>
                </div>
              </div> 
              <div className="col-4 margin-auto"> 
              <div className="">
              <Link to={"/ingredientes/"+ ingredient.name+"/edit"} style={{ textDecoration: "none" }}>
                  <a href={"/ingredientes/"+ ingredient.name+"/edit"} className="ingredient-icon" onClick={(event, i)=>{
                    goIngredient(ingredient.id)
                  }}>
                    <img src={editIcon} alt="" className="" />
                  </a>
                </Link>
                <a href="/" className="ingredient-icon" onClick={(event,i)=>{
                  event.preventDefault()
                  console.log(event,ingredient,"Event click")
                  deleteIngredient(ingredient)
                }}>
                  <img src={deleteIcon} alt="" className="" />
                </a>
              </div>
                
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProductIngredientPage;
 