import {
  URL_DOMAIN,
  getJSON,
  URL_P2,
  requestOptionsGet,
  requestOptionsPost,
  requestOptionsDelete,
  requestOptionsPut
} from "../globals";

const PRODUCT_URL = URL_DOMAIN + URL_P2[2];

const INGREDIENT_URL = URL_DOMAIN + URL_P2[4];

const supervisorId = localStorage.getItem("fk_supervisor");

/**
 * Indredients
 * @returns
 */

const getIngredientsForProduct = async (id) => {
  return fetch(
    PRODUCT_URL + "/get-ingredients-available-for-product?fk_product=" + id,
    requestOptionsGet
  ).then(getJSON);
};

const getIngredientsInProduct = async (id) => {
  return fetch(
    PRODUCT_URL + "/get-ingredients-attached-to-product?fk_product=" + id,
    requestOptionsGet
  ).then(getJSON);
}; 

const postNewIngredientToProduct = async (formData) => {
  requestOptionsPost.body = formData;
  return fetch(PRODUCT_URL + "/add-new-ingredient-to-product", requestOptionsPost).then(
    getJSON
  );
};

const postOldIngredientToProduct = async (formData) => {
  requestOptionsPost.body = formData;
  return fetch(PRODUCT_URL + "/add-existing-ingredient-to-product", requestOptionsPost).then(
    getJSON
  );
};

const deleteIngredientsInProduct = async (fk_product, fk_ingredient) => {
  return fetch(PRODUCT_URL + "/delete-ingredient-for-product?fk_product="+fk_product+"&fk_ingredient="+fk_ingredient, requestOptionsDelete).then(
    (response) => console.log(response)
  );
};

const getIngredients = async (page, rowsPerPage, searchQuery) => {
  let url = INGREDIENT_URL +
  `/paginated-ingredients-of-supervisor?fk_supervisor=${supervisorId}&results_per_page=${rowsPerPage}&page_number=${page+1}`
  if(searchQuery!==null){
    url += `&query_string=${searchQuery}`
  }
  return fetch(
    url,
    requestOptionsGet
  ).then(getJSON);
};

const getIngredient = async (id) => {
  return fetch(
    INGREDIENT_URL +
      `/get-ingredient?fk_ingredient=${id}`,
    requestOptionsGet
  ).then(getJSON);
};

const putIngredient = async (formData) => {
  requestOptionsPut.body = formData;
  return fetch(INGREDIENT_URL + "/edit-ingredient", requestOptionsPut).then(
    getJSON
  );
};

const postIngredient = async (formData) => {
  requestOptionsPost.body = formData;
  return fetch(INGREDIENT_URL + "/create-ingredient", requestOptionsPost).then(
    getJSON
  );
};

export { getIngredients, getIngredient, putIngredient, postIngredient, getIngredientsForProduct, getIngredientsInProduct, postNewIngredientToProduct,postOldIngredientToProduct, deleteIngredientsInProduct };
