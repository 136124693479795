import React, { useCallback, useEffect, useState } from "react";
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import {
  Checkbox,
  Chip,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import { Link } from "react-router-dom";
import { getSalePointsOfOrganization } from "../../services/utilsFranchise/utilsFranchise";

import { getWalletsNameContains2 } from "../../services/utilsWallet";
import {
  requestOptionsGet,
  getJSON,
  URL_REPORT,
  URL_DOMAIN,
} from "../../services/globals";
import "./ReportPage.scss";
import { getProductsOfOrganization } from "../../services/utilsProduct/utilsProduct";
import AutocompleteInput from "../../components/Autocomplete/AutocompleteInput";
import { getWorkShiftForSalePointInDateRange } from "../../services/utilsWorkShift";
import { convertUTCtoLocalTimeStringMovements } from "../../components/timezonefuncions";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      marginTop: 44,
    },
  },
};

const ReportPage = () => {
  /**
   * Const and Variables
   */
  const [salePointList, setSalePointList] = useState();

  const [selectedSps, setSelectedSps] = useState([]);

  const [workShifts, setWorkShifts] = useState();

  const [selectedWorkShift, setSelectedWorkShift] = useState();

  const [selectedUserType, setSelectedUserType] = useState();

  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [cashRegisterList, setCashRegisterList] = useState();

  const [salePointListInput, setSalePointListInput] = useState();

  const [productList, setProductList] = useState([]);

  const [productName, setProductName] = useState("");

  const [report, setReport] = useState();

  const [submitted, setSubmitted] = useState(false);

  const [loading, setLoading] = useState(false);

  const [loadingCustomers, setLoadingCustomers] = useState(false);

  const [wallets, setWallets] = useState([]);

  const [inputText, setInputText] = useState("");

  const reportList = [
    {
      id: 0,
      name: "Consolidado de recargas",
      url: "reports/get-payins-total-in-date-range",
      description:
        "Obtén un consolidado de la suma de recargas en un rango de fechas",
      allOrganization: true,
      salePoints: false,
      untilDate: false,
      clients: false,
      commingSoon: false,
      userType: false,
      multipleSalePoint: false,
      clientType: false,
      noDate: false,
      workShift: false,
      clientName: false,
    },
    {
      id: 2,
      name: "Detalle de recargas",
      url: "",
      description: "",
      allOrganization: true,
      salePoints: false,
      untilDate: false,
      clients: true,
      commingSoon: true,
      userType: false,
      multipleSalePoint: false,
      clientType: false,
      noDate: false,
      workShift: false,
      clientName: false,
    },
    {
      id: 3,
      name: "Extracto cuenta del cliente",
      url: "reports/get-movements-summary-for-date-range",
      description: "Genera el extracto de cuenta de un cliente en un rango de fechas",
      allOrganization: true,
      salePoints: false,
      untilDate: false,
      clients: true,
      commingSoon: false,
      userType: false,
      multipleSalePoint: false,
      clientType: false,
      noDate: false,
      workShift: false,
      clientName: true,
    },
    {
      id: 4,
      name: "Saldos a la fecha",
      url: "/reports/balance-to-date",
      description: "",
      allOrganization: true,
      salePoints: false,
      untilDate: true,
      clients: true,
      commingSoon: false,
      userType: true,
      multipleSalePoint: false,
      clientType: true,
      noDate: false,
      workShift: false,
      clientName: false,
    },
    {
      id: 5,
      name: "Extracto consolidado",
      url: "/reports/all-consolidated-extract",
      description: "",
      allOrganization: true,
      salePoints: false,
      untilDate: false,
      clients: false,
      commingSoon: false,
      userType: false,
      multipleSalePoint: false,
      clientType: false,
      noDate: false,
      workShift: false,
      clientName: false,
    },
    {
      id: 6,
      name: "Lista de precios",
      url: "/reports/price-list",
      description: "",
      allOrganization: true,
      salePoints: false,
      untilDate: false,
      clients: false,
      commingSoon: false,
      userType: false,
      multipleSalePoint: false,
      clientType: false,
      noDate: true,
      workShift: false,
      clientName: false,
    },
    {
      id: 7,
      name: "Resumen de inventario",
      url: "/reports/inventory-summary",
      description: "",
      allOrganization: true,
      salePoints: false,
      untilDate: false,
      clients: false,
      commingSoon: false,
      userType: false,
      multipleSalePoint: false,
      clientType: false,
      noDate: true,
      workShift: false,
      clientName: false,
    },
    {
      id: 7,
      name: "Resumen de producto por dia",
      url: "/reports/get-total-inventory-movements-of-date",
      description: "",
      allOrganization: true,
      salePoints: false,
      untilDate: true,
      clients: false,
      commingSoon: false,
      userType: false,
      multipleSalePoint: false,
      clientType: false,
      noDate: false,
      workShift: false,
      clientName: false,
    },
    {
      id: 8,
      name: "Consumos de la organización",
      url: "/reports/get-sales-detail-by-sale-point-report",
      description: "",
      allOrganization: true,
      salePoints: false,
      untilDate: false,
      clients: false,
      commingSoon: false,
      userType: false,
      multipleSalePoint: false,
      clientType: false,
      noDate: false,
      workShift: false,
      clientName: false,
    },
    {
      id: 9,
      name: "Resumen de inventario por fechas",
      url: "/reports/get-inventory-summary-between-dates",
      description: "",
      allOrganization: false,
      salePoints: true,
      untilDate: false,
      clients: false,
      commingSoon: false,
      userType: false,
      multipleSalePoint: false,
      clientType: false,
      noDate: false,
      workShift: false,
      clientName: false,
    },
    {
      id: 10,
      name: "Detalle de Recargas por nómina",
      url: "reports/get-payroll-deduction-detail-in-date-range",
      description: "",
      allOrganization: true,
      salePoints: false,
      untilDate: false,
      clients: false,
      commingSoon: false,
      userType: false,
      multipleSalePoint: false,
      clientType: false,
      noDate: false,
      workShift: false,
      clientName: false,
    },
    {
      id: 11,
      name: "Tirilla de Cierre",
      url: "reports/closing-inventory-summary",
      description: "",
      allOrganization: false,
      salePoints: true,
      untilDate: false,
      clients: false,
      commingSoon: false,
      userType: false,
      multipleSalePoint: false,
      clientType: false,
      noDate: false,
      workShift: true,
      clientName: false,
    },
    {
      id: 12,
      name: "Consolidado de ventas",
      url: "reports/sales-summary",
      description: "",
      allOrganization: true,
      salePoints: false,
      untilDate: false,
      clients: false,
      commingSoon: false,
      userType: false,
      multipleSalePoint: false,
      clientType: false,
      noDate: false,
      workShift: false,
      clientName: false,
    },
    {
      id: 12,
      name: "Consolidado de facturación electronica",
      url: "reports/get-einvoice-for-organziation-in-date-range",
      description: "",
      allOrganization: true,
      salePoints: false,
      untilDate: false,
      clients: false,
      commingSoon: false,
      userType: false,
      multipleSalePoint: false,
      clientType: false,
      noDate: false,
      workShift: false,
      clientName: false,
    },
  ];

  //Types  of users
  const usersTypes = [
    { value: 0, label: "Papas" },
    { value: 1, label: "Empleados" },
    { value: 2, label: "Estudiantes" },
    { value: 4, label: "Externos" },
  ];

  const fetchData = async () => {
    getSalePointsOfOrganization().then((data) => {
      console.log(data);
      let sptOptions = data["sale_points"].map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setSalePointList(sptOptions);
    });
    getProductsOfOrganization().then((data) => {
      let bigCategories = data["product_categories"];

      let productArray = [];
      for (let i = 0; i < bigCategories.length; i++) {
        const element = bigCategories[i]["products"];
        for (let j = 0; j < element.length; j++) {
          const element2 = element[j];
          productArray.push(element2);
        }
      }

      setProductList(productArray);
    });
  };

  const handleInputChangeInner = (event) => {
    setReport({ ...report, [event.target.name]: event.target.value });
    console.log({ [event.target.name]: event.target.value });
    if (event.target.name === "fk_sale_point") {
      let cashRegisters = salePointList.find(
        (element) => element.id === event.target.value
      );
      if (cashRegisters.cash_register) {
        setCashRegisterList(cashRegisters.cash_register);
      } else {
        setCashRegisterList([]);
      }
    }
  };

  const handleInputSelectReport = (event) => {
    let report2 = reportList.find(
      (element) => element.name === event.target.value
    );
    setReport({
      ...report,
      name: report2.name,
      url: report2.url,
      description: report2.description,
      allOrganization: report2.allOrganization,
      salePoints: report2.salePoints,
      untilDate: report2.untilDate,
      clients: report2.clients,
      commingSoon: report2.commingSoon,
      userType: report2.userType,
      multipleSalePoint: report2.multipleSalePoint,
      clientType: report2.clientType,
      noDate: report2.noDate,
      workShift: report2.workShift,
      clientName: report2.clientName,
    });
  };

  const handleChangeMultiSelect = (event) => {
    setSalePointListInput(event.target.value);
  };

  function newReport() {
    // window.location.reload();
    setReport({});
  }

  const fromStringToDatetime = (strDate) => {
    var dateStr = strDate;
    var dateArr = dateStr.split("-");
    var year = parseInt(dateArr[0]);
    var month = parseInt(dateArr[1]) - 1; // Months are zero-indexed in JavaScript (0 = January)
    var day = parseInt(dateArr[2]);

    var datetime = new Date(year, month, day);
    return datetime;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    let formDataBody = new FormData();
    for (const key in report) {
      formDataBody.append(key, report[key]);
    }

    let complementUrl = URL_DOMAIN + report["url"];
    // let complementUrl = "http://127.0.0.1:8000/api/web" + report["url"];
    console.log(complementUrl);

    if (report.allOrganization) {
      let fk_organization = JSON.parse(localStorage.getItem("profile"))[
        "profile"
      ]["organization"]["id"];
      complementUrl += `?fk_organization=${fk_organization}`;
      console.log(complementUrl);
    } else {
      if (report.salePoints) {
        // 2 options multipleSalePoints
        if (report.multipleSalePoint) {
        } else {
          complementUrl += `?fk_sale_point=${selectedSps.value}`;
          console.log(complementUrl);
        }
      }
    }
    if (report.untilDate) {
      //WE HAVE TO CREATE THE DATE WITH CURRENT UTC
      console.log("REPORT DATE", report, report.date);
      let datetime = fromStringToDatetime(report.date);
      // Set the time to the start of the day (00:00:00)
      datetime.setHours(0);
      datetime.setMinutes(0);
      datetime.setSeconds(0);

      // Create a new `Date` object for the end of the day
      var endDatetime = new Date(datetime);
      endDatetime.setHours(23);
      endDatetime.setMinutes(59);
      endDatetime.setSeconds(59);

      // Convert the start and end dates to ISO format
      // var startISO = datetime.toISOString();
      var endISO = endDatetime.toISOString();
      console.log(datetime, endDatetime);
      // complementUrl += `&date=${endISO}`;
      complementUrl += `&start_date=${datetime.toISOString()}&end_date=${endISO}`;
    } else if (!report.noDate) {
      complementUrl += `&start_date=${report["start_date"]}&end_date=${report["end_date"]}`;
    }

    if (report.userType) {
      complementUrl += `&user_type=${selectedUserType.value}`;
    }
    if (selectedWorkShift) {
      console.log(selectedWorkShift);
      complementUrl += `?fk_sale_point=${selectedSps.value}&fk_work_shift=${selectedWorkShift.value}`;
    }

    if(report.clientName){
      if(selectedCustomer.wallet_type === 1)
        complementUrl += `&wallet_type=${selectedCustomer.wallet_type}&id_number=${selectedCustomer.id_number}`;
      else if(selectedCustomer.wallet_type === 0)
        complementUrl += `&wallet_type=${selectedCustomer.wallet_type}&wallet_id=${selectedCustomer.id}`;
    }

    try {
      const report_response = await getReport(complementUrl);
      const blob = await report_response.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = url;
      let dateNow = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
      });
      a.download = report.name + dateNow + ".xlsx";
      a.click();
      window.URL.revokeObjectURL(url);
      setSubmitted(false);
    } catch (error) {
      console.error("Error al descargar el archivo", error);
    }
  };

  const debouncedSearch = useCallback(
    debounce((text) => {
      setLoadingCustomers(true);
      let fkOrganization = localStorage.getItem("fk_organization");
      getWalletsNameContains2(fkOrganization, text).then((data) => {
        // console.log(data);
        setWallets(data.wallets);
        setLoadingCustomers(false);
      });
    }, 500), 
    []
  );

  const handleTextChange = (event) => {
    setInputText(event.target.value);
    //debouncedSearch(event.target.value); 
  };

  useEffect(() => {
    debouncedSearch(' ');
  }, []);


  const getReport = async (url) => {
    return fetch(url, requestOptionsGet);
  };

  useEffect(() => {
    fetchData().then(() => { });
  }, []);

  useEffect(() => {
    if (report) {
      if (report["start_date"] && report["end_date"] && selectedSps.value && report.workShift) {
        setLoading(true);
        getWorkShiftForSalePointInDateRange(
          report["start_date"],
          report["end_date"],
          selectedSps.value
        ).then((data) => {
          console.log(data.work_shifts);
          let workShifts = data.work_shifts.map(
            ({ id, date_start, date_end, fk_worker }) => ({
              value: id,
              label:
                convertUTCtoLocalTimeStringMovements(date_start) +
                " - " +
                convertUTCtoLocalTimeStringMovements(date_end) +
                ": " +
                fk_worker.user.first_name +
                " " +
                fk_worker.user.last_name,
            })
          );
          setWorkShifts(workShifts);
          setLoading(false);
        });
      }
    }
  }, [report, selectedSps]);
  return (
    <main className="section-body">
      <h1 className="section-title">
        <FormattedMessage id="reportsOperatives" />
      </h1>
      <form
        onSubmit={handleSubmit}
        action=""
        className="row no-padding-row"
        id="createReport"
      >
        <div className="col-6 no-padding-6">
          <div className="container-input">
            <label className="form-label" htmlFor="franchiseInput">
              <FormattedMessage id="reportToGenerate" />
            </label>
            <Select
              required
              value={report ? report.name : ""}
              name="name"
              onChange={handleInputSelectReport}
              className="worker-form-select no-padding-right"
              id="reportNameInput"
            >
              {reportList &&
                reportList.map((report, i) => (
                  <MenuItem key={i} value={report.name}>
                    {report.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          {report && (
            <div>
              <div>
                {!report.commingSoon && (
                  <div>
                    {/* Date mangment - Start/End date */}
                    <div className="row">
                      <div className="col-6">
                        {!report.untilDate && !report.noDate && (
                          <div className="container-input row">
                            <label
                              className="form-label no-padding-6"
                              htmlFor="franchiseInput"
                            >
                              <FormattedMessage id="initialDateLbl" />
                            </label>
                            <input
                              required
                              type="date"
                              value={report.start_date ? report.start_date : ""}
                              name="start_date"
                              onChange={handleInputChangeInner}
                              className="worker-form-select"
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-6">
                        {!report.untilDate && !report.noDate && (
                          <div className="container-input row">
                            <label
                              className="form-label no-padding-6"
                              htmlFor="franchiseInput"
                            >
                              <FormattedMessage id="finalDateLbl" />
                            </label>
                            <input
                              required
                              type="date"
                              value={report.end_date ? report.end_date : ""}
                              name="end_date"
                              onChange={handleInputChangeInner}
                              className="worker-form-select"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Date mangment - Util date */}
                    {report.untilDate && (
                      <div className="container-input row">
                        <label
                          className="form-label no-padding-6"
                          htmlFor="franchiseInput"
                        >
                          <FormattedMessage id="choseeDateLbl" />
                        </label>
                        <input
                          required
                          type="date"
                          value={report.date ? report.date : ""}
                          name="date"
                          onChange={handleInputChangeInner}
                          className="worker-form-select"
                        />
                      </div>
                    )}

                    {/* Multiselect of sale points */}
                    {report.salePoints && (
                      <div className="row">
                        <label className="form-label" htmlFor="timezoneInput">
                          <FormattedMessage id="franchiseLbl" />
                        </label>
                        {console.log(salePointList)}
                        {salePointList && (
                          <AutocompleteInput
                            options={salePointList}
                            selectedOptions={selectedSps}
                            setSelected={setSelectedSps}
                            isClearable={false}
                            isSearchable={false}
                            isMulti={report.multipleSalePoint}
                            placeholder={"Selecciona un punto de venta"}
                          />
                        )}
                      </div>
                    )}
                    {workShifts && report.workShift && (
                      <div className="row">
                        <label className="form-label" htmlFor="timezoneInput">
                          Jornada de trabajo
                        </label>
                        {console.log(workShifts)}
                        {workShifts && (
                          <AutocompleteInput
                            options={workShifts}
                            selectedOptions={selectedWorkShift}
                            setSelected={setSelectedWorkShift}
                            isClearable={false}
                            isSearchable={false}
                            isMulti={false}
                            placeholder={"Selecciona una jornada de trabajo"}
                          />
                        )}
                      </div>
                    )}
                    {report.clientType && (
                      <div className="row">
                        <label className="form-label" htmlFor="timezoneInput">
                          <FormattedMessage id="userTypelbl" />
                        </label>
                        {console.log(usersTypes)}
                        {usersTypes && (
                          <AutocompleteInput
                            options={usersTypes}
                            selectedOptions={selectedUserType}
                            setSelected={setSelectedUserType}
                            isClearable={false}
                            isSearchable={false}
                            isMulti={false}
                            placeholder={"Selecciona un usuario"}
                          />
                        )}
                      </div>
                    )}

                    {report.clientName && (
                      <div className="row">
                        <label className="form-label" htmlFor="timezoneInput">
                          <FormattedMessage id="clientNameLbl" />
                        </label>


                        <div className="container-input row">
                              <Autocomplete
                                freeSolo
                                options={wallets}
                                getOptionLabel={(wallet) => wallet.name}
                                loading={loadingCustomers}
                                onChange={(event, newCustomer) => {
                                  setSelectedCustomer(newCustomer);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {loadingCustomers ? <CircularProgress color="inherit" size={20} /> : null}
                                          {params.InputProps.endAdornment}
                                        </>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </div>
                      </div>
                    )}

                    {loading && (
                      <div className="d-flex justify-content-center spinner-container m-2">
                        <div
                          className="spinner-border spinner-primary"
                          role="status"
                        >
                          <span className="visually-hidden">
                            Loading...
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="row justify-content-start">
                      <div className="col-12 d-flex justify-content-start report-buttons-container">
                        <button
                          className="btn btn-primary report-button"
                          type="submit"
                          form="createReport"
                          id="first-button-report"
                          disabled={submitted || loading}
                        >
                          {!submitted && (
                            <FormattedMessage id="downloadReportLbl" />
                          )}
                          {submitted && (
                            <div className="d-flex justify-content-center spinner-container">
                              <div
                                className="spinner-border spinner-secondary"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </div>
                          )}
                        </button>

                        <button
                          className="btn btn-outline-primary m-2"
                          onClick={(event, i) => {
                            event.preventDefault();
                            newReport();
                          }}
                        >
                          <FormattedMessage id="newReportLbl" />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="col-6 padding-1-rem">
          {report && (
            <div className="container-input">
              <label className="form-label" htmlFor="franchiseInput">
                <FormattedMessage id="reportDescriptLbl" />
              </label>
              {report.commingSoon && <p>PROXIMAMENTE</p>}
              <p>{report.description ? report.description : ""}</p>
            </div>
          )}
        </div>
      </form>
    </main>
  );
};

export default ReportPage;

// {report.productInfo && (
//   <div className="container-input">
//      <Autocomplete
//       value={productName}
//       onChange={(event, newValue) => {
//         if (typeof newValue === "string") {
//           setProductName({
//             title: newValue,
//           });

//         } else if (newValue && newValue.inputValue) {
//           setProductName({
//             title: newValue.inputValue,
//           });

//         } else {
//           setProductName(newValue);

//         }
//       }}
//       filterOptions={(options, params) => {
//         const filtered = filter(options, params);

//         // Suggest the creation of a new value
//         /**
//         if (params.inputValue !== '') {
//           filtered.push({
//             inputValue: params.inputValue,
//             name: `Add "${params.inputValue}"`,
//           });
//         }
// */
//         return filtered;
//       }}
//       selectOnFocus
//       clearOnBlur
//       handleHomeEndKeys
//       options={productList}
//       getOptionLabel={(option) => {
//         // Value selected with enter, right from the input

//         if (typeof option === "string") {
//           return option;
//         }
//         // Add "xxx" option created dynamically
//         if (option.inputValue) {
//           return option.inputValue;
//         }

//         if (option.title) {
//           return option.title;
//         }

//         // Regular option
//         return option.name;
//       }}
//       renderOption={(option) => option.name}
//       freeSolo
//       renderInput={(params) => (
//         <TextField
//           required
//           {...params}
//           label={<FormattedMessage id="productLbl" />}
//         />
//       )}
//     />
//   </div>
// )}
