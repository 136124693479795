import React from "react";
import "./ChipCustom.scss";
import Chip from "@material-ui/core/Chip";

const ChipCustom = ({ dataToChip, chipClick }) => {
  const dataChip = dataToChip;

  const [flag, setFlag] = React.useState(true);
  const handleClickChip = () => {
    setFlag(!flag);

    chipClick(dataChip);
  };
  return (
    <>
      {dataChip && (
        <li key={dataChip.key}>
          <Chip
            color={flag ? "default" : "primary"}
            label={dataChip.label}
            className="chip-margin"
            onClick={handleClickChip}
          />
        </li>
      )}
    </>
  );
};

export default ChipCustom;
