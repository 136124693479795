import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { createMassiveEInvoice, previewMassiveEInvoice } from "../../../services/utilsEInvoice";
import "./eInvoiceModals.scss";
import FeedbackAlert from "../../../components/FeedbackAlert/FeedbackAlert";
import { Alert } from "@mui/material";
import { Snackbar } from "@material-ui/core";
import "../../../index.scss";
import { formatMoney } from "../../currencyFuntions";
import SalesPointChipsArray from "../SalesPointChipsArray/SalesPointChipsArray";
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';

const MassiveEIModal = ({ onClose }) => {
  const [loadingEInvoice, serLoadingEInvoice] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);

  const [resMassiveEI, setResMassiveEI] = useState();

  const [datesToInvoice, setDatesToInvoice] = useState({
    start_date: "",
    end_date: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [excludedSalePoints, setExcludedSalePoints] = useState([]);

  const handleSnackbarClose = (event, reason, success = false) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const updateExcludedSalePoints = (newSalePoints) => {
    setExcludedSalePoints(newSalePoints);
  };

  const handleSubmitEInvoice = async () => {
    serLoadingEInvoice(true);

    let organization = localStorage.getItem("fk_organization");
    let excluded = excludedSalePoints.map(salePoint => salePoint.key);
    let jsonObj = {
      start_date: datesToInvoice.start_date,
      end_date: datesToInvoice.end_date,
      fk_organization: organization,
      excluded_sale_points: excluded,
    };
    let res = await createMassiveEInvoice(JSON.stringify(jsonObj));
    if (res.error) {
      // TODO Show error
      setErrorMessage('No se generaron facturas');
    } else {
      // TODO show done and
      setOpenSnackbar(true);
      console.log(res);
      setResMassiveEI(res.customers);
      // setTimeout(() => {
      //   onClose();
      // }, 3000);
    }
  };

   const handlePreviewEInvoice = async () => {
    setLoadingPreview(true);
  
    const organization = localStorage.getItem("fk_organization");
    const excluded = excludedSalePoints.map(salePoint => salePoint.key);
    const jsonObj = {
      start_date: datesToInvoice.start_date,
      end_date: datesToInvoice.end_date,
      fk_organization: organization,
      excluded_sale_points: excluded,
    };
  
    try {
      const res = await previewMassiveEInvoice(jsonObj);
      const blob = await res.blob();
  
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = url;
      const dateNow = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
      });
      a.download = `EInvoicePreview_${dateNow}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setErrorMessage('Error al descargar el archivo');
      console.error("Error al descargar el archivo", error);
    }
    setLoadingPreview(false);
  };


  const validateDates = () => {
    const startDate = new Date(datesToInvoice.start_date);
    const endDate = new Date(datesToInvoice.end_date);

    if (datesToInvoice.start_date === "") {
      setErrorMessage("Porfavor seleccionar la fecha de inicio");
      return false;
    }

    if (datesToInvoice.end_date === "") {
      setErrorMessage("Porfavor seleccionar la fecha de fin");
      return false;
    }

    if (startDate >= endDate) {
      setErrorMessage(
        "La fecha de finalización debe ser mayor que la fecha de inicio"
      );
      return false;
    }

    setErrorMessage("");
    return true;
  };

  const handleInputChangeInner = (event) => {
    const { name, value } = event.target;
    setErrorMessage("");
    setDatesToInvoice((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));
  };

  const statusEIToString = (status) => {
    switch (status) {
      case 422:
        return "No emitida";
      case 200:
        return "Factura emitida";
      default:
        return ""
    }
  }

  return (
    <>
      <div className="modal-full-page">
        <div className="background-modal-container" onClick={onClose}>
          <div className="background-color-modal-container"></div>
        </div>
        <div className="modal-container-eInvoice">
          {!resMassiveEI && (
            <div>
              {" "}
              <div className="row margin-bottom-1rem">
                <h1 className="section-title">
                  <FormattedMessage id="massiveEInvoiceTitle" />
                </h1>
              </div>
              <div className="row margin-bottom-1rem">
                <p className="info-detail-invoice">
                  Se generarán facturas electrónicas consolidadas para todos los
                  responsables económicos, en el rango de fechas especificado.
                </p>
              </div>
              <form id="createEInvoice" action="" className="">
                <div className="row margin-bottom-1rem">
                  <div className="col-6">
                    <label
                      className="form-label no-padding-6"
                      htmlFor="franchiseInput"
                    >
                      <FormattedMessage id="sinceLbl" />
                    </label>
                    <input
                      required
                      type="date"
                      value={datesToInvoice.start_date}
                      name="start_date"
                      onChange={handleInputChangeInner}
                      className="worker-form-select"
                    />
                  </div>
                  <div className="col-6">
                    <label
                      className="form-label no-padding-6"
                      htmlFor="franchiseInput"
                    >
                      <FormattedMessage id="toLbl" />
                    </label>
                    <input
                      required
                      type="date"
                      value={datesToInvoice.end_date}
                      name="end_date"
                      onChange={handleInputChangeInner}
                      className="worker-form-select"
                    />
                  </div>
                </div>

                <div className="row margin-bottom-1rem">
                  <div className="col-12">
                    <label
                      className="form-label no-padding-6"
                      htmlFor="franchiseInput"
                    >
                      Excluir puntos de venta
                    </label>
                    <SalesPointChipsArray updateSalePoints={updateExcludedSalePoints}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label
                      className="form-label no-padding-6"
                      htmlFor="franchiseInput"
                    >
                      Vista previa
                    </label>

                  </div>
                </div>
                <div>
                  <button
                        className="btn btn-outline-primary m-2"
                        onClick={(event) => {
                          if (validateDates()) {
                            event.preventDefault();
                            handlePreviewEInvoice();
                          }
                        }}
                      >
                    
                      {!loadingPreview && (
                        <>
                          <DownloadIcon style={{ marginRight: '8px' }} />
                          <FormattedMessage id="downloadLbl" />
                        </>
                      )}
                      {loadingPreview && (
                        <div className="d-flex justify-content-center spinner-container">
                          <div
                            className="spinner-border spinner-secondary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      )}
                  </button>
                </div>
                <div className="row mt-2 justify-content-center">
                  <div className="col-12 d-flex justify-content-center">
                    <button
                      className="btn btn-outline-primary m-2"
                      onClick={onClose}
                    >
                      <FormattedMessage id="cancelLbl" />
                    </button>
                    <button
                      className="btn btn-primary report-button"
                      type="submit"
                      form="createEInvoice"
                      id="first-button-report"
                      onClick={(event) => {
                        if (validateDates()) {
                          event.preventDefault();
                          handleSubmitEInvoice();
                        }
                      }}
                      // disabled={submitted}
                    >
                      {!loadingEInvoice && (
                        <FormattedMessage id="createMassiveElectronicInvoicelbl" />
                      )}
                      {loadingEInvoice && (
                        <div className="d-flex justify-content-center spinner-container">
                          <div
                            className="spinner-border spinner-secondary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
          {resMassiveEI && (
            <>
              <div className="row margin-bottom-1rem">
                <h1 className="section-title">
                  Resultado consolidado masivo de facturas
                </h1>

              </div>
              <div className="row resEI-container">
                {Object.keys(resMassiveEI).map((email, index) => (
                  <div className="row ei-sub-container" key={index}>
                    <h6 className="sub-header">{email}</h6>
                    <p>Estado: {statusEIToString(resMassiveEI[email].status)}</p>
                    {resMassiveEI[email].status === 422 && <p>Mensaje: {resMassiveEI[email].text}</p>}
                    {resMassiveEI[email].status === 200 && (<>
                      <p>Valor emitido: {formatMoney(resMassiveEI[email].invoice_body.invoice.amounts.totalAmount)}</p>
                      <p>Documento: {resMassiveEI[email].response_lafactura.invoiceResult.documento.document}</p>
                      </>)}
                  </div>
                ))}
              </div>
              <div className="row justify-content-center">
                  <div className="col-12 d-flex justify-content-center">
                    <button
                      className="btn btn-outline-primary m-2"
                      onClick={onClose}
                    >
                      REGRESAR
                    </button>
                    {/* <button
                      className="btn btn-primary report-button"
                      type="submit"
                      form="createReport"
                      id="first-button-report"
                      onClick={() => {
                        if (validateDates()) {
                          handleSubmitEInvoice();
                        }
                      }}
                      // disabled={submitted}
                    >
                      {!loadingEInvoice && (
                        <FormattedMessage id="createMassiveEInvoicelbl" />
                      )}
                      {loadingEInvoice && (
                        <div className="d-flex justify-content-center spinner-container">
                          <div
                            className="spinner-border spinner-secondary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      )}
                    </button> */}
                  </div>
                </div>
            </>
          )}
        </div>
      </div>

      <FeedbackAlert
        type={"success"}
        vertical={"bottom"}
        horizontal={"right"}
        idmessage={"eInvoiceSucces"}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
      />
    </>
  );
};

export default MassiveEIModal;
