
import { useContext, useEffect, useState } from "react";
import Context from "../../context/UserContext";
import Countdown from 'react-countdown';
import KuruahLogo from "../../assets/icons/kuruah-logo.svg";
import "./SuspensionPage.scss"
import useUser from "../../services/usersManage/useUser";

export const SuspensionPage = () =>{

    const { suspensionCompany, setSuspensionCompany} = useContext(Context);

    const {isLogged, logout }  = useUser();

    useEffect(() => {
        console.log("THIS IS USEEFFECT",suspensionCompany);
    }, [suspensionCompany]);

    const Completionist = () => {
        if(suspensionCompany ==1){
            setSuspensionCompany(0);
            localStorage.setItem("suspension",0);
            window.location.href = "./home";
        }
        else {
            logout();
            window.location.href = "./";

        }

        return <span>Click para entrar a la plataforma</span>
    };

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            //setDisableButton(false);
            return <Completionist />;
        } else {
            // Render a countdown
            return <span>{minutes}:{seconds}</span>;
        }
    };
    return(
    <main>
        <nav className="navbar navbar-demo" id="nav-suspension">
            <img src={KuruahLogo} alt="kuruah-logo" className="kuruah-Logo" />
        </nav>
        <section className="color-background suspension-container">
            {console.log(suspensionCompany,"EN SUSPENSION PAGE")}
            {suspensionCompany == "1" && (
                <article>
                    <h1 className="yellow-font title-size">
                        Aviso de suspensión
                    </h1>
                    <p className="paragraph-font">
                        Tienes facturas pendientes por pagar. Por favor paga tus facturas pendientes antes del 22 de este mes para seguir evitar suspensión del servicio.
                    </p>
                    <button className="btn btn-outline-principal m-2 center-button" disabled={true}>
                        PODRÁS ENTRAR A LA PLATAFORMA EN <Countdown date={Date.now() + 30000} renderer={renderer}/> SEGUNDOS
                    </button>
                </article>
            )}
            {suspensionCompany == "2" && (
                <article>
                    <h1 className="red-font title-size">
                    Suspensión parcial
                    </h1>
                    <p className="paragraph-font">
                        Tienes facturas pendientes por pagar. No tienes acceso a la plataforma web ni a la aplicación móvil como supervisor. Tus operarios podrán usar su aplicación para reportar ventas. Sin embargo, en caso de no recibir el pago antes del 30 de este mes, se suspenderá el servicio totalmente. Si ya hiciste tu pago, envía un correo a gestion@kuruah.com o comunícate con nuestra línea de soporte (+57 320 9272969).
                    </p>
                    <button className="btn btn-outline-principal m-2 center-button" disabled={true}>
                        LA SESIÓN SE CERRARÁ EN <Countdown date={Date.now() + 30000} renderer={renderer}/> SEGUNDOS
                    </button>
                </article>
            )}
            {suspensionCompany == "3" && (
                <article>
                    <h1 className="red-font title-size">
                    Aviso de suspension
                    </h1>
                    <p className="paragraph-font">
                        Tienes facturas pendientes por pagar. No tienes acceso a la plataforma web ni a la aplicación móvil como supervisor. Tus operarios tampoco pueden reportar ventas. Si ya hiciste tu pago, envía un correo a gestion@kuruah.com o comunícate con nuestra línea de soporte (+57 320 9272969).
                    </p>
                    <button className="btn btn-outline-principal m-2 center-button" disabled={true}>
                        LA SESIÓN SE CERRARÁ EN <Countdown date={Date.now() + 30000} renderer={renderer}/> SEGUNDOS
                    </button>
                </article>
            )}

        </section>
    </main>
    );
}