import { FormattedMessage } from "react-intl";
import CustomTable from "../../components/Table/Table";
import MassiveEIModal from "../../components/supervisor/eInvoiceModals/massiveEInvoiceModal";
import CustomerEInvoiceModal from "../../components/supervisor/eInvoiceModals/CustomerEInvoiceModal";
import { eInvoiceSummaryAllOrganization } from "../../services/utilsEInvoice";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import InfoPrimaryIcon from "../../assets/icons/einvoice.svg";
import EInvoiceWhite from "../../assets/icons/einvoice-white.svg";
import { useState } from "react";
import SearchBar from "../../components/searchBar/searchBar";
import { LinearProgress } from "@material-ui/core";
import {
  formatCurrency,
  getRangeDate,
  labelCustomerType,
} from "../../components/supervisor/eInvoiceModals/einvoiceAuxFuntions";
import ImageDefault from "../../assets/icons/DEFAULT IMG/avatar-supervisor.jpg";

const EInvoice = () => {
  const [tableRows, setTableRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [customers, setCustomers] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState(null);

  const [loadingTable, setLoadingTable] = useState(false);
  const [doEInvoice, setDoEInvoice] = useState(false);
  const [showCustomerEInvoice, setShowCustomerEInvoice] = useState(false);
  const [showMassiveEInvoice, setShowMassiveEInvoice] = useState(false);

  let tableHeaders = [
    " ",
    "financialmanagerHeader",
    "idNumberHeader",
    "roleHeader",
    "lastIssuanceRangeHeader",
    "accumulatedValueHeader",
    "actionsHeader",
  ];
  const fetchData = async () => {
    setLoadingTable(true);
    let fkOrganization = localStorage.getItem("fk_organization");
    let res = await eInvoiceSummaryAllOrganization(fkOrganization);
    console.log(res.customers);
    let transformedRows = res.customers.map((customer) =>
      transformRows(
        customer.image,
        customer.first_name + " " + customer.last_name,
        customer.id_number,
        customer.customer_type,
        customer.period_start_date,
        customer.period_end_date,
        customer.amount_without_invoice / 100,
        customer.customer_wallet,
        customer
      )
    );
    setCustomers(transformedRows);
    setTableRows(transformedRows);
    setLoadingTable(false);
  };

  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const filtered = customers.filter(
      (customer) =>
        customer.name.toLowerCase().includes(lowercasedQuery) ||
        customer.idNumber.toLowerCase().includes(lowercasedQuery)
    );
    setTableRows(filtered);
  }, [searchQuery, customers]);

  const handleTextSearch = (event) => {
    console.log(event);
    setSearchQuery(event);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const transformRows = (
    img,
    name,
    idNumber,
    rol,
    startDate,
    endDate,
    aggregate,
    id,
    customer
  ) => {
    // let lastMovements = getMovementTypeString(lastMovement);
    // let currentInventoryString = currentInventory + " UND";
    // const localTimeString = convertUTCtoLocalTimeString(date);
    let rolString = labelCustomerType(rol);
    let rangeDate = getRangeDate(startDate, endDate);
    let currency = formatCurrency(aggregate);
    let image = img ? img : ImageDefault;
    return {
      image,
      name,
      idNumber,
      rolString,
      rangeDate,
      currency,
      actions: (
        <>
          <div className="flex">
            <img
              src={InfoPrimaryIcon}
              alt="info"
              onClick={() => {
                setCurrentCustomer(customer);
                setShowCustomerEInvoice(true);
              }}
              className="table-icon"
            />
          </div>
        </>
      ),
    };
  };

  let onCloseMassive = (e) => {
    e.preventDefault();
    setShowMassiveEInvoice(true);
  };

  return (
    <main className="section-body table-einvoice-container">
      <section className="up-section-einvoice">
        <h1 className="section-title">
          <FormattedMessage id="eInvoiceTitle" /> - Emisión de facturas
        </h1>
        <form action="">
          <button className="btn btn-primary m-2" onClick={onCloseMassive}>
            <div>
              <img
                src={EInvoiceWhite}
                alt="info"
                onClick={() => ""}
                className=""
              />{" "}
              CONSOLIDADO MASIVO
            </div>
          </button>
        </form>
      </section>

      <section>
        {/* Filters */}
        <SearchBar
          input={searchQuery}
          onChange={handleTextSearch}
          nombre="Customer"
        />
      </section>
      <section className="">
        {/* table */}
        {loadingTable && <LinearProgress />}
        <CustomTable
          headers={tableHeaders}
          rows={tableRows}
          rowTypes={[[0, "image"]]}
          pagination={""}
        />
      </section>
      <section>
        {showMassiveEInvoice && (
          <MassiveEIModal
            onClose={() => {
              setShowMassiveEInvoice(false);
              fetchData();
            }}
          />
        )}
        {showCustomerEInvoice && (
          <CustomerEInvoiceModal
            onClose={() => {
              setShowCustomerEInvoice(false);
              fetchData();
            }}
            customer={currentCustomer}
          />
        )}
      </section>
    </main>
  );
};

export default EInvoice;
