import useUser from "./usersManage/useUser";


/**
 * PROD DOMAIN

**/
const URL_DOMAIN_ROOT = "https://market-server.ontrack.global/ws";
const URL_REPORT = "https://market-server.ontrack.global/ws/reports";
const URL_DOMAIN_MARKET = "https://market-server.ontrack.global/ws/web";

const URL_AUTH = "https://market-server.ontrack.global/ws";
const URL_HELP = "https://market-server.ontrack.global/ws/api/";
const URL_DOMAIN = "https://market-server.ontrack.global/ws/api/web/";
const URL_SALES = "http://market-server.ontrack.global/api/cashier/";


const URL_DOMAIN_SUPERADMIN = "https://market-server.ontrack.global/ws/api/web-superadmin/";

/**
 *DEV DE DOMAIN - Market
//  */
// const URL_AUTH = "https://development-market.ontrack.global/de/ws";
// const URL_DOMAIN_ROOT = "https://development-market.ontrack.global/de/ws";
// const URL_REPORT = "https://development-market.ontrack.global/de/ws/reports";
// const URL_DOMAIN_MARKET = "https://development-market.ontrack.global/de/ws/web";

// const URL_HELP = "https://development-market.ontrack.global/ws/api/";
// const URL_DOMAIN = "https://development-market.ontrack.global/ws/api/web/";
// const URL_DOMAIN_SUPERADMIN = "https://development-market.ontrack.global/ws/api/web-superadmin/";

/**
 * DOMAIN - LOCAL
 */

// const URL_DOMAIN = "http://127.0.0.1:8000/api/web/";
// const URL_DOMAIN_SUPERADMIN = "http://127.0.0.1:8000/api/web-superadmin/";
// const URL_HELP = "http://127.0.0.1:8000/api/";

// const URL_AUTH = "http://127.0.0.1:8000/api/"
// const URL_DOMAIN_ROOT = "http://127.0.0.1:8000/ws/web";
// const URL_REPORT = "http://127.0.0.1:8000/ws/reports";
// const URL_DOMAIN_MARKET = "http://127.0.0.1:8000/ws/market-web";
// const URL_SALES = "http://127.0.0.1:8000/api/cashier/";



const URL_P2 = [
  "/franchises",
  "/operators",
  "/products",
  "/authentication",
  "/ingredients",
  "/supervisor",
  "/company",
  "/invoice-schemas",
  "/information",
  "/demo",
  "/inventory-transactions",
  "/provider"
];

const URL_INVOICE_SCHEMAS = URL_DOMAIN + URL_P2[7];

const URL_OPERATORS = URL_DOMAIN + URL_P2[1];


const URL_PRODUCTS = URL_DOMAIN + URL_P2[2];
const URL_INGREDIENTS = URL_DOMAIN + URL_P2[4];

const URL_SUPERVISOR = URL_DOMAIN + URL_P2[5];
const URL_COMPANY = URL_DOMAIN + URL_P2[6];

let myHeaders = new Headers();
if (localStorage.getItem("bckt")) {
  const auth_user = JSON.parse(localStorage.getItem("profile")).username;
  const token = window.localStorage.getItem("bckt");
  console.log("User", auth_user);
  const megaToken = "Basic " + btoa(auth_user + ":" + token);
  myHeaders.append("Authorization", megaToken);
}

var requestOptionsGet = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow",
};
var requestOptionsPost = {
  method: "POST",
  headers: myHeaders,
  body: "",
  redirect: "follow",
};

var requestOptionsPut = {
  method: "PUT",
  headers: myHeaders,
  body: "",
  redirect: "follow",
};

var requestOptionsDelete = {
  method: "DELETE",
  headers: myHeaders,
  body: "",
  redirect: "follow",
};

const getJSON = (response) => response.json();

export {
  URL_DOMAIN,
  URL_DOMAIN_MARKET,
  URL_P2,
  URL_REPORT,
  URL_DOMAIN_ROOT,
  URL_AUTH,
  URL_SALES,
  URL_DOMAIN_SUPERADMIN,
  URL_HELP,
  getJSON,
  requestOptionsGet,
  requestOptionsPost,
  requestOptionsPut,
  requestOptionsDelete,
};

