import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { createEInvoiceForCustomer, eInvoiceSalesExcluded } from "../../../services/utilsEInvoice";
import { formatCurrency, labelCustomerType } from "./einvoiceAuxFuntions";
import FeedbackAlert from "../../../components/FeedbackAlert/FeedbackAlert";
import ImageDefault from "../../../assets/icons/DEFAULT IMG/avatar-supervisor.jpg";


const CustomerEInvoiceModal = ({ onClose, customer }) => {
  const [loadingEInvoice, serLoadingEInvoice] = useState(false);

  const [datesToInvoice, setDatesToInvoice] = useState({
    start_date: "",
    end_date: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); 

  const handleSnackbarClose = (event, reason, success = false) => {
    
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSubmitEInvoice = async () => {
    serLoadingEInvoice(true);
    let organization = localStorage.getItem("fk_organization");
    let jsonObj = {
      email: customer.email,
      start_date: datesToInvoice.start_date,
      end_date: datesToInvoice.end_date,
      fk_organization: organization,
    };
    let res = await createEInvoiceForCustomer(JSON.stringify(jsonObj));
    if (res.error) {
      // TODO Show error
      setErrorMessage('No se generaron facturas')
      serLoadingEInvoice(false);
    } else {
      // TODO show done and
      setSnackbarMessage("eInvoiceSucces"); 
      setOpenSnackbar(true);
      onClose();
    }
  };

  const handleSaveChanges = async () => {
    serLoadingEInvoice(true);
    let organization = localStorage.getItem("fk_organization");
    let jsonObj = {
      email: customer.email,
      start_date: datesToInvoice.start_date,
      end_date: datesToInvoice.end_date,
      fk_organization: organization,
    };
    let res = await eInvoiceSalesExcluded(JSON.stringify(jsonObj));
    if (res.error) {
      setErrorMessage('No se guardaron los cambios')
      serLoadingEInvoice(false);
    } else {
      setSnackbarMessage("salesExcludedSuccess");
      setOpenSnackbar(true);
      onClose();
    }
  };

  const handleInputChangeInner = (event) => {
    const { name, value } = event.target;
    setErrorMessage("");
    setDatesToInvoice((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };


  const validateDates = () => {
    const startDate = new Date(datesToInvoice.start_date);
    const endDate = new Date(datesToInvoice.end_date);
    const customerStartDate = new Date(customer.period_start_date);


    if (datesToInvoice.start_date === "") {
      setErrorMessage("Porfavor seleccionar la fecha de inicio");
      return false;
    }

    if (datesToInvoice.end_date === "") {
      setErrorMessage("Porfavor seleccionar la fecha de fin");
      return false;
    }

    if (startDate >= endDate) {
      setErrorMessage(
        "La fecha de finalización debe ser mayor que la fecha de inicio"
      );
      return false;
    }

    if (startDate < customerStartDate) {
      setErrorMessage(
        "La fecha de inicio debe ser mayor o igual a la fecha de facturas para el cliente"
      );
      return false;
    }

    setErrorMessage("");
    return true;
  };

  return (
    <>
      <div className="modal-full-page">
        <div className="background-modal-container" onClick={onClose}>
          <div className="background-color-modal-container"></div>
        </div>
        <div className="modal-container">
          <div className="row margin-bottom-1rem">
            <h1 className="section-title">
              <FormattedMessage id="customerEInvoiceTitle" />
            </h1>
          </div>
          <div className="row margin-bottom-1rem customer-info-container">
            <div className="col">
              <img
                src={customer.image ? customer.image : ImageDefault}
                alt=""
                className="customer-modal-img"
              />
            </div>
            <div className="col">
              <p>{customer.first_name + " " + customer.last_name}</p>
              <p>{labelCustomerType(customer.customer_type)}</p>
            </div>
          </div>
          <form id="create-massive-invoice" action="" className="">
            <div className="row margin-bottom-1rem">
              <div className="col-6">
                <label
                  className="form-label no-padding-6"
                  htmlFor="franchiseInput"
                >
                  <FormattedMessage id="initialDateLbl" />
                </label>
                <input
                  type="date"
                  value={datesToInvoice.start_date}
                  name="start_date"
                  onChange={handleInputChangeInner}
                  className="worker-form-select"
                />
              </div>
              <div className="col-6">
                <label
                  className="form-label no-padding-6"
                  htmlFor="franchiseInput"
                >
                  <FormattedMessage id="finalDateLbl" />
                </label>
                <input
                  type="date"
                  value={datesToInvoice.end_date}
                  name="end_date"
                  onChange={handleInputChangeInner}
                  className="worker-form-select"
                />
              </div>
            </div>
            {errorMessage && (
              <div className="row">
                <div className="col">
                  <p className="text-danger">{errorMessage}</p>
                </div>
              </div>
            )}
            <div className="row">
              <div>
                {/* <label
                className="form-label no-padding-6"
                htmlFor="franchiseInput"
              >
                <h5>
                  Sin facturar: {customer.transactions_without_invoice.length}
                </h5>
              </label> */}

                <h5>Acumulado para rango de fechas:</h5>
                <h6 className="">
                  Sin facturar: {customer.transactions_without_invoice.length}{" "}
                  transacciones
                </h6>
                {customer.transactions_without_invoice.length >
                  0 && (
                    <div>
                      <h6 className="">
                        Desde:
                        {
                          customer.transactions_without_invoice[0].date.split(
                            " "
                          )[0]
                        }
                      </h6>
                      <h6 className="">
                        Hasta:
                        {
                          customer.transactions_without_invoice[
                            customer.transactions_without_invoice.length - 1
                          ].date.split(" ")[0]
                        }
                      </h6>
                    </div>
                  )}

                <h6 className="modal-customer-amount">
                  {formatCurrency(customer.amount_without_invoice / 100)}
                </h6>
                <div style={{ marginBottom: '10px' }}>
                  <input
                    type="checkbox"
                    id="exclude"
                    name="exclude"
                    value="Excluir"
                    checked={isChecked}
                    onChange={handleOnChange} />{' '}Excluir de factura electrónica real (¡CUIDADO!)
                </div>

              </div>
            </div>
            {/* <div className="row">
            <h5>Hijos</h5>
            {customer.trackables.map((trackable) => (
              <p>{trackable.name}</p>
            ))}
          </div> */}
            <div className="row justify-content-center">
              <div className="col-12 d-flex justify-content-center">
                <button
                  className="btn btn-outline-primary m-2"
                  type="button"
                  onClick={onClose}
                >
                  <FormattedMessage id="cancelLbl" />
                </button>


                {!isChecked ? (
                  <button
                    className="btn btn-primary report-button"
                    form="create-massive-invoice"
                    id="massive-invoice-button"
                    onClick={(event) => {
                      
                      if (validateDates()) {
                        event.preventDefault()
                        handleSubmitEInvoice();
                      }
                    }}
                  >
                    {!loadingEInvoice && (
                      <FormattedMessage id="createMassiveEInvoicelbl" />
                    )}
                    {loadingEInvoice && (
                      <div className="d-flex justify-content-center spinner-container">
                        <div className="spinner-border spinner-secondary" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    )}
                  </button>
                ) : (
                  <button
                    className="btn btn-primary report-button"
                    form="create-massive-invoice"
                    id="save-changes-button"
                    onClick={(event) => {
                      
                      if (validateDates()) {
                        event.preventDefault()
                        handleSaveChanges();
                      }
                    }}
                  >
                    {!loadingEInvoice && (
                      <FormattedMessage id="excludeBill" />
                    )}
                    {loadingEInvoice && (
                      <div className="d-flex justify-content-center spinner-container">
                        <div className="spinner-border spinner-secondary" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    )}
                  </button>
                )}
              </div>
            </div>
           
          </form>
        </div>
      </div>
      <FeedbackAlert
        type={"success"}
        vertical={"bottom"}
        horizontal={"right"}
        idmessage={snackbarMessage}
        open={openSnackbar}
        setOpen={handleSnackbarClose}
      />
    </>
  );
};

export default CustomerEInvoiceModal;



