import {
    URL_DOMAIN,
    getJSON,
    URL_P2,
    requestOptionsGet,
    requestOptionsPost,
    requestOptionsPut,
  } from "../globals";
  
  const URL_FRANCHISES = URL_DOMAIN + URL_P2[0];
  
  const supervisorId = localStorage.getItem("fk_supervisor");
  
  /**
   * Franchises
   * @returns
   */
  
  const getFranchiseTypes = async () => {
    return fetch(
      URL_FRANCHISES +
        "/get-franchise-types-of-supervisor?fk_supervisor=" +
        supervisorId,
      requestOptionsGet
    ).then(getJSON);
  };


  const getFranchiseType = async (franchiseTypeId) => {
    return fetch(
      URL_FRANCHISES +
        "/get-franchise-type?fk_franchise_type=" +
        franchiseTypeId,
      requestOptionsGet
    ).then(getJSON);
  };

  const getAllFranchiseTypesOfSupervisor = async () => {
    return fetch(
      URL_FRANCHISES +
        "/get-all-franchise-types-of-supervisor?fk_supervisor=" +
        supervisorId,
      requestOptionsGet
    ).then(getJSON);
  }

  const putFranchiseType = async (formData) => {
    requestOptionsPut.body = formData;
    return fetch(URL_FRANCHISES + "/edit-franchise-type", requestOptionsPut).then(
      getJSON
    );
  };

  const postFranchiseType = async (formData) => {
    requestOptionsPost.body = formData;
    return fetch(URL_FRANCHISES + "/create-franchise-type", requestOptionsPost).then(
      getJSON
    );
  };
  
  export { getFranchiseTypes, getFranchiseType, getAllFranchiseTypesOfSupervisor, putFranchiseType, postFranchiseType };
  