import React, { Fragment, useState } from "react";
import { DatePicker } from "@material-ui/pickers";

function YearMonthPicker(dateValue, setDateValue) {

  return (
    <Fragment>
      <DatePicker
        views={["year"]}
        label="Year only"
        value={dateValue}
        onChange={setDateValue}
      />

      <DatePicker
        views={["year", "month"]}
        label="Year and Month"
        helperText="With min and max"
        minDate={new Date("2018-03-01")}
        maxDate={new Date("2018-06-01")}
        value={dateValue}
        onChange={setDateValue}
      />

      <DatePicker
        variant="inline"
        openTo="year"
        views={["year", "month"]}
        label="Year and Month"
        helperText="Start from year selection"
        value={dateValue}
        onChange={setDateValue}
      />
    </Fragment>
  );
}

export default YearMonthPicker;
