import {
  URL_DOMAIN,
  URL_HELP,
  URL_DOMAIN_MARKET,
  getJSON,
  URL_P2,
  URL_SALES,
  requestOptionsGet,
  requestOptionsPost,
  requestOptionsPut
} from "../globals";

const URL_FINAL = URL_DOMAIN + URL_P2[2];

const URL_PRODUCT = URL_DOMAIN + URL_P2[2];


const supervisorId = localStorage.getItem("fk_supervisor");

const organizationId = localStorage.getItem('fk_organization');
/**
 * Franchises
 * @returns
 */

const getProductsOfOrganization = async () => {
  try {
    const response = await fetch(
      URL_DOMAIN +
      "product/get-all-products-of-organization?fk_organization=" +
      organizationId,
      requestOptionsGet
    );

    if (!response.ok) {
      throw new Error("Error al obtener los datos de ventas");
    }

    return getJSON(response);
  } catch {
    throw new Error("Error al obtener los datos de ventas: ");
  }
};

const getProductById = async (id) => {
  return fetch(
    URL_DOMAIN + "product/get-product-detail?fk_product=" + id,
    requestOptionsGet
  ).then(getJSON);
};

const getExcelTemplate = async (id) => {
  return fetch(
    URL_HELP + "inventory/web/get-inventory-excel-template?fk_organization=" + id,
    requestOptionsGet
  );
};

const getExcelTemplate_sales = async (id) => {
  return fetch(
    URL_HELP + "web/templates/get-sales-template=" + id,
    requestOptionsGet
  );
};

const postProduct = async (formData) => {
  requestOptionsPost.body = formData;
  return fetch(URL_DOMAIN + "product/create-product", requestOptionsPost).then(
    getJSON
  );
};

const putProduct = async (formData) => {
  requestOptionsPut.body = formData;
  return fetch(URL_DOMAIN + "product/edit-product", requestOptionsPut).then(
    getJSON
  );
};
const uploadProducts = async (formData) => {
  requestOptionsPost.body = formData;
  return fetch(
    URL_DOMAIN + "product/upload-initial-products", requestOptionsPost
  ).then(getJSON);
}

const uploadProductsExcel = async (formData) => {
  requestOptionsPost.body = formData;
  return fetch(
    URL_HELP + "inventory/web/load-products-from-excel", requestOptionsPost
  ).then(getJSON);
}

// const uploadSalesExcel = async (formData) => {
//   requestOptionsPost.body = formData;
//   return fetch(
//     URL_SALES + "sales/upload-excel-sale", requestOptionsPost
//   ).then(getJSON);
// }

const uploadSalesExcel = async (formData) => {
  requestOptionsPost.body = formData;
  try {
    const response = await fetch(URL_HELP + "inventory/web/upload-excel-sale", requestOptionsPost);
    if (!response.ok) {
      const errorData = await response.json();
      const error = new Error("Error al subir el archivo");
      error.response = { status: response.status, data: errorData };
      throw error;
    }
    return await response.json();
  } catch (error) {
    console.error("Error en uploadSalesExcel:", error);
    throw error;
  }
};

const activateSpecialPrices = async (jsonBody) => {
  requestOptionsPut.body = jsonBody;
  return fetch(
    URL_DOMAIN + "product/activate-special-prices",
    requestOptionsPut
  ).then(getJSON);
};

const disactivateSpecialPrices = async (jsonBody) => {
  requestOptionsPut.body = jsonBody;
  return fetch(
    URL_DOMAIN + "product/disactivate-special-prices",
    requestOptionsPut
  ).then(getJSON);
};

export { getProductsOfOrganization, getProductById, postProduct, putProduct, uploadProducts, getExcelTemplate, uploadProductsExcel, activateSpecialPrices, disactivateSpecialPrices, getExcelTemplate_sales, uploadSalesExcel };
