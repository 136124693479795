import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FormattedMessage } from "react-intl";
import GetAppIcon from "@material-ui/icons/GetApp";
import "./Operations.scss";
import { getExcelTemplate_sales, uploadSalesExcel } from "../../services/utilsProduct/utilsProduct";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const Operations = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activateUpdatedSuccess, setActivateUpdatedSuccess] = useState(false);
  const [messageId, setMessageId] = useState("operationsUpdatedSuccess");
  const [ventasCreadas, setVentasCreadas] = useState(0); // Estado para ventas creadas
  const [errores, setErrores] = useState([]); // Estado para los errores
  const [open, setOpen] = useState(false); // Estado para el diálogo
  const [errorMessage, setErrorMessage] = useState(""); // Estado para el mensaje de error

  const fileInputRef = useRef(null);

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const onDrop = (acceptedFiles) => {
    console.log("Archivos aceptados:", acceptedFiles);
    setSelectedFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".xlsx",
    multiple: false,
  });

  const handleInputChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleReload = () => {
    window.location.reload();
  };

  const handleGoHome = () => {
    window.location.href = "/home";
  };

  const handleDownload = async () => {
    try {
      let fkOrganization = localStorage.getItem("fk_organization");
      const response = await getExcelTemplate_sales(fkOrganization);
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = url;
      a.download = "plantilla ventas masivas ontrack market.xlsx";
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al descargar el archivo", error);
    }
  };

  const handleUpload = () => {
    if (selectedFile !== null) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("fk_organization", localStorage.getItem("fk_organization"));
      formData.append("excel_file", selectedFile);
      uploadSalesExcel(formData).then((data) => {
        setIsLoading(false);
        setActivateUpdatedSuccess(true);
        setMessageId("operationsUploadedSuccess");

        // Verificar si successful_sales, failed_sales y extra_errors existen
        const successfulSales = data.successful_sales || [];
        const failedSales = data.failed_sales || [];
        const extraErrors = data.extra_errors || [];

        // Combinar errores de failed_sales y extra_errors
        const allErrors = [
          ...failedSales.map(failedSale => failedSale.error),
          ...extraErrors.map(extraError => extraError.error)
        ];

        // Actualizar ventas creadas y errores
        setVentasCreadas(successfulSales.length); // Cantidad de ventas exitosas
        setErrores(allErrors); // Mensajes de error combinados
      }).catch((error) => {
        setIsLoading(false);
        console.error("Error al subir el archivo", error);
        if (error.response && error.response.status === 422) {
          console.log("Error 422:", error.response.data.error);
          setErrorMessage(error.response.data.error);
          setOpen(true);
        } else {
          console.error("Error al subir el archivo", error);
        }
      });
    }
  };

  return (
    <main className="section-body table-operations-container">
      {/* Sección principal con flexbox */}
      <div className="operations-container">
        {/* Sección de Subir Archivo */}
        <section className="up-section-operations">
          <h1 className="section-title">
            <FormattedMessage id="operationsTitle" /> - Subir ventas masivas
          </h1>
          <p className="section-description">
            Diligencia la plantilla con las operaciones que deseas subir al sistema.
          </p>
          <form action="">
            <button
              type="button"
              className="btn btn-outline-primary m-2"
              onClick={handleDownload}
            >
              <GetAppIcon style={{ marginRight: "8px" }} />
              DESCARGAR FORMATO
            </button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleInputChange}
              accept=".xlsx"
            />
          </form>
          <hr style={{ border: "1px solid grey", margin: "20px 0" }} />
          <p className="section-description">Carga el formato diligenciado</p>
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              border: "2px dashed #007bff",
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <input {...getInputProps()} />
            <p>
              Haz clic aquí para seleccionar el archivo
            </p>
          </div>
          {selectedFile && (
            <p className="file-name">
              <strong>Archivo seleccionado:</strong> {selectedFile.name}</p>
          )}
          <hr style={{ border: "1px solid grey", margin: "20px 0" }} />
          <p className="section-description">
            Genera las ventas desde el formato diligenciado.
          </p>
          <div className="button-container">
            <button
              type="button"
              className="btn btn-primary m-1"
              onClick={handleUpload} // Cambiado para procesar el archivo al hacer clic
            >
              GENERAR VENTAS
            </button>
            {activateUpdatedSuccess && (
              <div className="success-container">
                <button
                  type="button"
                  className="btn btn-primary m-1"
                  onClick={handleReload} // Cambiado para procesar el archivo al hacer clic
                >
                  CARGAR OTRO ARCHIVO
                </button>
                {/* <Button variant="contained" color="primary" onClick={handleReload} style={{ marginRight: "10px" }}>
                  Cargar otro archivo
                </Button> */}
              </div>
            )}
          </div>
        </section>

        {/* Sección de Resultados - Manejo de errores filas excel desde el back */}
        <section className="results-section">
          {isLoading && (
            <div className="loading-container">
              <CircularProgress />
              <p>Cargando ventas...</p>
            </div>
          )}
          {activateUpdatedSuccess && (
            <div className="success-container">
              <p>Proceso finalizado.</p>
            </div>
          )}
          <h1 className="section-title">
            Resultados
          </h1>
          <p>Ventas creadas: <strong>{ventasCreadas}</strong></p>
          {errores.length > 0 && (
            <div>
              <h1 className="section-title">Errores:</h1>
              <ul>
                {errores.map((error, index) => (
                  <li key={index}>
                    <ArrowRightIcon style={{ marginRight: "8px" }} />
                    {error}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </section>
      </div>

      {/* Diálogo de error */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Error al procesar el archivo"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </main>
  );
};

export default Operations;