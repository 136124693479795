import {
    URL_DOMAIN,
    URL_DOMAIN_ROOT,
    getJSON,
    URL_P2,
    requestOptionsGet,
    requestOptionsPost,
    requestOptionsPut,
  } from "../globals";

  const URL_FINAL = URL_DOMAIN + URL_P2[6];
  const URL_DEMO = URL_DOMAIN_ROOT + URL_P2[9];
  
  const getCompanyById = async (id) => {
    return fetch(
      URL_FINAL + "/get-company?id=" + id,
      requestOptionsGet
    ).then(getJSON);
  };
  
  const getCompanies = async (page, rowsPerPage, searchQuery) => {
    let url = `${URL_FINAL}/get-all-companies?results_per_page=${rowsPerPage}&page_number=${page+1}`
    if (searchQuery !== null) {
      url += `&query_string=${searchQuery}`
    }
    return fetch(
      url,
      requestOptionsGet
    ).then(getJSON);
  };

  const postCompany = async (formData) => {
    requestOptionsPost.body = formData;
    return fetch(URL_FINAL + "/create-company", requestOptionsPost).then(
      getJSON
    );
  };
  
  const putCompany = async (formData) => {
    requestOptionsPut.body = formData;
    return fetch(URL_FINAL + "/edit-company", requestOptionsPut).then(
      getJSON
    );
  };

  const getCompaniesSimple= async () => {
    return fetch(
      URL_FINAL + "/simple-get-all-companies",
      requestOptionsGet
    ).then(getJSON);
  };

  const editDemoProgress = async (formData) =>{
    requestOptionsPut.body = formData;
    return fetch(URL_FINAL + "/edit-demo-progress", requestOptionsPut).then(
      getJSON
    ); 
  }

  const postDemoCompany = async (formData) => {
    requestOptionsPost.body = formData;
    return fetch(URL_DEMO + "/create-franchise-demo", requestOptionsPost).then(
      getJSON
    );
  };

  const editCompanySuspension = async (formData) => {
    requestOptionsPut.body = formData;
    return fetch(URL_FINAL + "/edit-suspension-state", requestOptionsPut).then(
      getJSON
    );
  };
export {getCompanyById,getCompanies, postCompany, putCompany, getCompaniesSimple, editDemoProgress, postDemoCompany, editCompanySuspension}