import React, { useEffect, useState } from "react";
import "../../../index.scss";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { getFranchiseTypes } from "../../../services/utilsFranchiseType/utilsFranchiseType";
import {
  getIngredient,
  postIngredient,
  putIngredient,
} from "../../../services/utilsIngredient/utilsIndredient";
import "./IngredientForm.scss";
import { getPaginatedProductCategories } from "../../../services/utilsProductCategory/utilsProductCategory";
import { useHistory } from "react-router-dom";
import { Alert } from "@mui/material";

const filter = createFilterOptions();

export const IngredientForm = () => {
  // ----------------------------------------------------
  // ------------- Variables & Constants ----------------
  // ----------------------------------------------------

  const [ingredient, setIngredient] = useState({});

  const [loading, setLoading] = useState(true);

  const [formTitle, setFormTitle] = useState("createNewIngredient");

  const [submitLabel, setSubmitLabel] = useState("createIngredientLbl");

  const [productCategoryList, setProductCategoryList] = useState([]);

  const [productCategory, setProductCategory] = useState("");

  const [franchiseTypeList, setFranchiseTypeList] = useState([]);

  const [franchiseType, setFranchiseType] = useState("");

  const [submitted, setSubmitted] = useState(false);

  const urlLocation = useLocation();

  const [snackbarMessage, setSnackbarMessage] = useState(
    <Alert severity="success">NONE</Alert>
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [successSnackbar, setSuccessSnackbar] = useState(false);

  const history = useHistory();

  // ----------------------------------------------------
  // ------------------ Async calls ---------------------
  // ----------------------------------------------------

  const fetchData = async () => {
    getPaginatedProductCategories(0, 100).then((data) => {
      console.log(data);
      setProductCategoryList(data.product_categories);
    });

    getFranchiseTypes().then((data) => {
      console.log(data);
      setFranchiseTypeList(data.franchise_types);
    });

    if (urlLocation.pathname.includes("edit")) {
      let ingredientId = localStorage.getItem("fk_ingredient");
      setFormTitle("editIngredient");
      setSubmitLabel("saveIngredientLbl");

      getIngredient(ingredientId).then((data) => {
        console.log(data);
        setIngredient(data.ingredient);
        setProductCategory(data.ingredient.product_category.name);
        setFranchiseType(data.ingredient.product_category.franchise_type.id);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData().then(() => {});
  }, []);

  // ----------------------------------------------------
  // ------------------- Handlers -----------------------
  // ----------------------------------------------------

  const handleInputChangeInner = (event) => {
    console.log(event.target.name);
    console.log(event.target.value);
    setIngredient({ ...ingredient, [event.target.name]: event.target.value });
    if (event.target.name === "fk_franchise_type") {
      setFranchiseType(event.target.value);
    }
  };

  const handleReversibleChange = (event) => {
    console.log(event.target.name);
    console.log(event.target.checked);
    setIngredient({
      ...ingredient,
      [event.target.name]: event.target.checked ? 1 : 0,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    let formDataBody = new FormData();
    for (const key in ingredient) {
      if (key === "reversible") {
        console.log(ingredient[key]);
        formDataBody.append(key, ingredient[key] ? 1 : 0);
      } else if (key === "product_category") {
        formDataBody.append("fk_product_category", ingredient[key].id);
      } else {
        formDataBody.append(key, ingredient[key]);
      }
    }
    if (productCategory.id) {
      formDataBody.append("fk_product_category", productCategory.id);
    }
    console.log(ingredient);
    if (urlLocation.pathname.includes("edit")) {
      formDataBody.append("fk_ingredient", ingredient.id);
      putIngredient(formDataBody).then((data) => {
        if (data.error === undefined) {
          setSuccessSnackbar(true);
          setSnackbarMessage(
            <Alert severity="success">
              Se ha actualizado el ingrediente con éxito
            </Alert>
          );
          setOpenSnackbar(true);
        } else {
          setSuccessSnackbar(false);
          setSnackbarMessage(
            <Alert severity="error">Error: {data.error}</Alert>
          );
          setOpenSnackbar(true);
        }
        setSubmitted(false);
      });
    } else {
      postIngredient(formDataBody).then((data) => {
        if (data.error === undefined) {
          setSuccessSnackbar(true);
          setSnackbarMessage(
            <Alert severity="success">
              Se ha creado el ingrediente con éxito
            </Alert>
          );
          setOpenSnackbar(true);
        } else {
          setSuccessSnackbar(false);
          setSnackbarMessage(
            <Alert severity="error">Error: {data.error}</Alert>
          );
          setOpenSnackbar(true);
        }
        setSubmitted(false);
      });
    }
  };

  const checkKeyDown = (event) => {
    if (event.code === "Enter") event.preventDefault();
  };

  const handleSnackbarClose = (event, reason, success = false) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    if (successSnackbar) {
      history.push("/productos");
    }
  };

  // ----------------------------------------------------
  // -------------------- Render ------------------------
  // ----------------------------------------------------
  return (
    <main className="section-body">
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        {snackbarMessage}
      </Snackbar>
      <h1 className="section-title">
        <FormattedMessage id={formTitle} />
      </h1>
      {!loading && (
        <form
          onSubmit={handleSubmit}
          className="row g-3"
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <div className="col-6 mb-3">
            <TextField
              required
              className="form-control"
              id="standard-basic"
              label={<FormattedMessage id="nameLbl" />}
              value={ingredient.name ? ingredient.name : ""}
              name="name"
              onChange={handleInputChangeInner}
            />
          </div>
          <div className="mb-3 description-container">
            <TextField
              required
              className="form-control"
              id="standard-basic"
              label={<FormattedMessage id="descriptionLbl" />}
              value={ingredient.description ? ingredient.description : ""}
              name="description"
              onChange={handleInputChangeInner}
              rows={4}
              multiline
            />
          </div>
          <div className="row">
            <div className="col-6">
              <div className="mb-3 mt-4">
                <Autocomplete
                  // disabled={urlLocation.pathname.includes("edit")}
                  value={productCategory}
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      setProductCategory({
                        title: newValue,
                      });
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setProductCategory({
                        title: newValue.inputValue,
                      });
                    } else {
                      setProductCategory(newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== "") {
                      filtered.push({
                        inputValue: params.inputValue,
                        name: `Add "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={productCategoryList}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input

                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }

                    if (option.title) {
                      return option.title;
                    }

                    // Regular option
                    return option.name;
                  }}
                  renderOption={(option) => option.name}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={<FormattedMessage id="categoryLbl" />}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-6 mb-3">
              <label className="form-label" htmlFor="franchiseTypeInput">
                <FormattedMessage id="franchiseTypeLbl" />
              </label>
              <Select
                disabled={urlLocation.pathname.includes("edit")}
                required
                value={franchiseType ? franchiseType : ""}
                name="fk_franchise_type"
                onChange={handleInputChangeInner}
                className="worker-form-select"
                id="franchiseTypeInput"
              >
                {franchiseTypeList &&
                  franchiseTypeList.map((franchise_type, i) => (
                    <MenuItem key={i} value={franchise_type.id}>
                      {franchise_type.name}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="mb-3 mt-4">
                <TextField
                  required
                  className="form-control"
                  id="standard-basic"
                  label={<FormattedMessage id="unitLbl" />}
                  value={ingredient.unit ? ingredient.unit : ""}
                  name="unit"
                  onChange={handleInputChangeInner}
                />
              </div>
            </div>
            <div className="col-6 mb-3">
              <label className="form-label" htmlFor="ivaInput">
                <FormattedMessage id="ivaLbl" />
              </label>
              <Select
                required
                value={ingredient.iva !== undefined ? ingredient.iva : ""}
                name="iva"
                onChange={handleInputChangeInner}
                className="worker-form-select"
                id="ivaInput"
              >
                <MenuItem value={0}>0%</MenuItem>
                <MenuItem value={0.05}>5%</MenuItem>
                <MenuItem value={0.19}>19%</MenuItem>
              </Select>
            </div>
          </div>
          <div className="row">
            <div className="col-6 mb-3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ingredient.reversible ? true : false}
                    onChange={handleReversibleChange}
                    name="reversible"
                    color="primary"
                  />
                }
                label={<FormattedMessage id="inventoryReversibleLbl" />}
              />
            </div>
            <div className="col-6 mb-3">
              <TextField
                className="form-control"
                label={<FormattedMessage id="internalCodeLbl" />}
                value={ingredient.internal_code ? ingredient.internal_code : ""}
                name="internal_code"
                onChange={handleInputChangeInner}
              />
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-12 d-flex justify-content-end">
              <Link className="" to={`/productos`}>
                <button className="btn btn-outline-primary m-2">
                  <FormattedMessage id="cancelLbl" />
                </button>
              </Link>
              <button
                className="btn btn-primary m-2"
                type="submit"
                disabled={submitted}
              >
                {!submitted && <FormattedMessage id={submitLabel} />}
                {submitted && (
                  <div className="d-flex justify-content-center spinner-container">
                    <div
                      className="spinner-border spinner-primary"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </button>
            </div>
          </div>
        </form>
      )}
      {loading && (
        <div className="d-flex justify-content-center spinner-container">
          <div className="spinner-border spinner-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </main>
  );
};
