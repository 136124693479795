import {
    URL_DOMAIN,
    getJSON,
    URL_P2,
    requestOptionsGet, URL_DOMAIN_ROOT,
} from "../globals";

const URL_FRANCHISES = URL_DOMAIN + URL_P2[8];

const supervisorId = localStorage.getItem("fk_supervisor");

/**
 *
 * @returns
 */

const getFullBalance = async () => {
    return fetch(
        URL_FRANCHISES +
        "/full-balance?fk_supervisor=" +
        supervisorId,
        requestOptionsGet
    ).then(getJSON);
};

const getTodaySales = async () => {
    //let supervisorId = 39;
    return fetch(
        URL_FRANCHISES +
        "/recent-sales?fk_supervisor=" +
        supervisorId,
        requestOptionsGet
    ).then(getJSON);
};

const getTableOccupancy = async () => {
    return fetch(
        URL_FRANCHISES +
        "/table-occupancy?fk_supervisor=" +
        supervisorId,
        requestOptionsGet
    ).then(getJSON);
};

const getCashHistogram = async (fk_franchise, starDate, endDate) => {
    return fetch(URL_FRANCHISES + "/cash-histogram?fk_franchise=" + fk_franchise + "&start_date=" + starDate + "&end_date=" + endDate, requestOptionsGet).then(getJSON);
}

const getCashMovements = async (fk_franchise, starDate, endDate) => {
    requestOptionsGet.signal = Timeout(120).signal;
    return fetch(URL_DOMAIN_ROOT + "/cash-counts/transaction-history?fk_franchise="+fk_franchise+"&start_date="+starDate+"&end_date="+endDate, requestOptionsGet).then(getJSON);
}
const getSalesHistogram = async (fk_franchise, starDate, endDate) =>{
    requestOptionsGet.signal = Timeout(120).signal;
    return fetch(URL_DOMAIN_ROOT + "/web/information/sales-histogram?fk_franchise="+fk_franchise+"&start_date="+starDate+"&end_date="+endDate, requestOptionsGet).then(getJSON);
};

export const Timeout = (time) => {
  let controller = new AbortController();
  setTimeout(() => controller.abort(), time * 1000);
  return controller;
};
export {getFullBalance, getTodaySales, getTableOccupancy, getCashHistogram, getCashMovements, getSalesHistogram};