//Dependencias
import React, { useState, useContext } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "@material-ui/styles";

import { createTheme } from "@material-ui/core/styles";
import Context, { UserContextProvider } from "./context/UserContext";
import useUser from "./services/usersManage/useUser";
import { Redirect } from "react-router";
//Archivos de ayuda
import { LOCALES } from "./i18n/locales";
import messages from "./i18n/messages";
//Componentes
import { LogIn } from "./pages/loginPage/Login";
import Navbar from "./components/supervisor/vertialmenu/NavBar";
import { NavBarProfile } from "./components/supervisor/navBarPorfile/navBarProfile";

//Franchise
import { SalePointForm } from "./pages/Franchise/FranchiseForm/SalePointForm";
import MyFranchisesPage from "./pages/BigPages/MyFranchisesPage";
//Operators
import WorkerPage from "./pages/Operator/WorkerPage/WorkerPage";
import OperatorDetail from "./pages/Operator/OperatorDetail/OperatorDetail";
import WorkerForm from "./pages/Operator/WorkerForm/WorkerForm";
//Products
import MyProductPage from "./pages/BigPages/MyProductPage";
import ProductListPage from "./pages/Product/ProductListPage/ProductListPage";
import ProductForm from "./pages/Product/ProductForm/ProductForm";

import "bootstrap/dist/css/bootstrap.min.css";
import Subscribe from "./pages/Subscribe/Subscribe";
import { IngredientForm } from "./pages/Ingredient/IngredientForm/IngredientForm";
import { ProductCategoryForm } from "./pages/ProductCategory/ProductCategoryForm/ProductCategoryForm";
import { FranchiseTypeForm } from "./pages/FranchiseType/FranchiseTypeForm/FranchiseTypeForm";
import CredentialsPage from "./pages/Authentication/CredentialsPage/CredentialsPage";

//Home
import HomePage from "./pages/HomePage/HomePage";

//Invoice Schema
import InvoiceSchemaForm from "./pages/Invoice Schemas/InvoiceSchemaForm/InvoiceSchemaForm";
//Reports
import ReportPage from "./pages/Reports/ReportPage";
//Profile
import Profile from "./pages/Profile/Profile";
//SUPERADMIN
/**Company */
import { CompanyForm } from "./pages/Company/CompanyForm/CompanyForm";
import SupervisorListPage from "./pages/Supervisor/SupervisorListPage/SupervisorListPage";
import SupervisorForm from "./pages/Supervisor/SupervisorForm/SupervisorForm";
//Demo
import { DemoMain } from "./pages/Demo/DemoMain";
import CompanyList from "./pages/Company/CompanyList/CompanyList";
/**
 * Suspension
 */
import { SuspensionPage } from "./pages/SuspensionPage/SuspensionPage";
/**
 * Inventory page
 * @returns
 */
import InventoryPage from "./pages/Inventory/InventoryPage";
import ProductInventory from "./pages/Inventory/ProductInventory/ProductInventory";
/**
 * CashCount
 * @returns
 */
import CashCount from "./pages/CashCount/CashCount";
import SalesBalance from "./pages/SalesBalance/SalesBalance";

/**
 * Stock Page
 * @returns
 */
import StockPage from "./pages/StockPage/StockPage";
import CritialProviderPage from "./pages/StockPage/CritialProviderPage/CritialProviderPage";
import SalePointTypeCard from "./components/supervisor/SalePointTypeCard/SalePointTypeCard";
import SalePointTypeForm from "./pages/SalePointType/SalePointTypeForm/SalePointTypeForm";
import SalePointTypeEdit from "./pages/SalePointType/SalePointTypeEdit/SalePointTypeEdit";
import SalePointTypeContext from "./context/StoreIdContext";
import HomeMarket from "./pages/HomePage/HomeMarket";
import OrganizationList from "./pages/Organization/OrganizationList/OrganizationList";
import OrganizationForm from "./pages/Organization/OrganizationForm/OrganizationForm";
import InventoryHome from "./pages/Inventory/InventoryHome/InventoryHome";
import WalletPage from "./pages/WalletPage/WalletPage";
import DashboardSales from "./pages/Dashboard/Dashboard Sales/DashboardSales";
import TagsListPage from "./pages/TagsPage/TagsListPage/TagsListPage";
import TagsPageManagement from "./pages/TagsPage/TagsPageManagement/TagsPageManagement";
import EInvoice from "./pages/eInvoice/eInvoice";
import Operations from "./pages/Operations/Operations";

// Constants
import { Role, RoleType } from "./constants/parameter";
// Guards
import GuardedRoute from "./guards/GuardedRoute";
import TransactionInProgress from "./pages/PayIn/TransactionInProgress";

export const protectedGroups = [
  {
    role: Role.ADMIN, // SUPERADMIN
    type: null,
    routes: [
      {
        path: "/organizaciones",
        component: OrganizationList,
      },
      {
        path: "/organizaciones/create",
        component: OrganizationForm,
      },
      {
        path: "/organizaciones/:id/edit",
        component: OrganizationForm,
      },
      {
        path: "/supervisores",
        component: SupervisorListPage,
      },
      {
        path: "/supervisores/create",
        component: SupervisorForm,
      },
      {
        path: "/supervisores/:id/edit",
        component: SupervisorForm,
      },
    ],
  },
  {
    role: [Role.ADMIN, Role.SUPERVISOR], // General routes
    type: [
      RoleType.SUPERVISOR,
      RoleType.ACCOUNTING,
      RoleType.ADMIN,
      RoleType.SUPPORT,
      RoleType.MANAGER,
    ],
    routes: [
      {
        path: "/dashboard-sales",
        component: DashboardSales,
      },
      {
        path: "/perfil/:id",
        component: Profile,
      },
      {
        path: "/home",
        component: HomeMarket,
      },
      {
        path: "/informes",
        component: ReportPage,
      },
      {
        path: "/billeteras",
        component: WalletPage,
      },
      {
        path: "/eInvoice",
        component: EInvoice,
      },
      {
        path: "/operaciones",
        component: Operations,
      },
    ],
  },
  {
    role: [Role.ADMIN, Role.SUPERVISOR], //  0->Supervisor 2->Admin 4->Manager
    type: [RoleType.SUPERVISOR, RoleType.ADMIN, RoleType.MANAGER],
    routes: [
      {
        path: "/pasillos",
        component: MyFranchisesPage,
      },
      {
        path: "/pasillos/create",
        component: SalePointTypeForm,
      },
      {
        path: "/pasillos/:id/edit",
        component: SalePointTypeEdit,
      },
      {
        path: "/puntos-de-venta/create",
        component: SalePointForm,
      },
      {
        path: "/puntos-de-venta/:id/edit",
        component: SalePointForm,
      },
      {
        path: "/etiquetas",
        component: TagsListPage,
      },
      {
        path: "/manejo-etiquetas/:id",
        component: TagsPageManagement,
      },
      {
        path: "/trabajadores",
        component: WorkerPage,
      },
      {
        path: "/trabajadores/create",
        component: WorkerForm,
      },
      {
        path: "/trabajadores/:id/edit",
        component: WorkerForm,
      },
      {
        path: "/ingredientes/:id/edit",
        component: IngredientForm,
      },
      {
        path: "/ingredientes/create",
        component: IngredientForm,
      },
    ],
  },
  {
    role: [Role.ADMIN, Role.SUPERVISOR], // 2->Admin 3->Soporte 4->Manager
    type: [RoleType.ADMIN, RoleType.SUPPORT, RoleType.MANAGER],
    routes: [
      {
        path: "/categorias-de-producto/:id/edit",
        component: ProductCategoryForm,
      },
      {
        path: "/categorias-de-producto/create",
        component: ProductCategoryForm,
      },
      {
        path: "/productos",
        component: MyProductPage,
      },
      {
        path: "/productos/create",
        component: ProductForm,
      },
      {
        path: "/productos/:id/edit",
        component: ProductForm,
      },
    ],
  },
  {
    role: [Role.ADMIN, Role.SUPERVISOR], // 0->Supervisor 2->Admin 3->Soporte 4->Manager
    type: [
      RoleType.SUPERVISOR,
      RoleType.ADMIN,
      RoleType.SUPPORT,
      RoleType.MANAGER,
    ],
    routes: [
      {
        path: "/inventarios",
        component: InventoryHome,
      },
      {
        path: "/inventarios/:id",
        component: ProductInventory,
      },
    ],
  },
];

//DEPENDENCES TRY
function Routes() {
  const mystyle = {
    display: "flex",
    overflowX: "hidden",
  };
  const mybodyStyle = {
    backgroundColor: "#F3F3F3",
    width: "100%",
    maxHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  };

  const [language, setLanguage] = useState(LOCALES.SPANISH);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#0070A1",
      },
      secondary: {
        main: "#00A1D1",
      },
    },
  });

  const [currentSalePointTypeId, setCurrentSalePointTypeId] = useState();

  const { userRole } = useContext(Context);

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider locale={language} messages={messages[language]}>
        <BrowserRouter>
          <Switch>
            {/* handle home */}
            <Route exact path="/">
              {userRole == Role.SUPERVISOR ? (
                <Redirect to="/home" />
              ) : userRole == Role.ADMIN ? (
                <Redirect to="/organizaciones" />
              ) : (
                <LogIn />
              )}
            </Route>

            {/* public routes */}
            <Route exact path="/subscribe" component={Subscribe}></Route>
            <Route path="/demo" exact component={DemoMain} />
            <Route path="/suspension" exact component={SuspensionPage} />
            <Route
              path="/transaction-in-progress"
              exact
              component={TransactionInProgress}
            />

            <div style={mystyle}>
              <Navbar />
              <div style={mybodyStyle}>
                <NavBarProfile />
                <SalePointTypeContext.Provider
                  value={{ currentSalePointTypeId, setCurrentSalePointTypeId }}
                >
                  {protectedGroups.map((group, index) => (
                    <ProtectedRoutesGroup
                      key={index}
                      role={group.role}
                      type={group.type}
                    >
                      {group.routes.map((route, idx) => (
                        <GuardedRoute
                          key={idx}
                          path={route.path}
                          exact
                          component={route.component}
                        />
                      ))}
                    </ProtectedRoutesGroup>
                  ))}
                  {/* Not implemented yet */}
                  {/* <Route path="/resoluciones-facturacion/create" exact component={InvoiceSchemaForm} />
                  <Route path="/resoluciones-facturacion/:id/edit" exact component={InvoiceSchemaForm} />
                  <Route path="/ventas" exact component={SalesBalance} />
                  <Route path="/stock" exact component={CritialProviderPage} />
                  <Route path="/companias/create" exact component={CompanyForm} />
                  <Route path="/companias/:id/edit" exact component={CompanyForm} /> */}
                </SalePointTypeContext.Provider>
              </div>
            </div>
          </Switch>
        </BrowserRouter>
      </IntlProvider>
    </ThemeProvider>
  );
}

const ProtectedRoutesGroup = ({ role, type, children }) => {
  return React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { role, type });
    }
    return child;
  });
};

export default Routes;
