import * as React from 'react';
import { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import SearchBar from "../../../components/searchBar/searchBar";
import { getSalePointsOfOrganization } from "../../../services/utilsFranchise/utilsFranchise";

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function SalesPointChipsArray({updateSalePoints}) {
  //List of all SalesPoints
  const [salePointListDefault, setSalePointListDefault] = useState([]);
  //Filtered list of SalesPoints
  const [salePointList, setSalePointList] = useState([]);
  //Text of filter input
  const [inputSalePoint, setInputSalePoint] = useState("");
  //Chips of selected SalesPoints
  const [chipData, setChipData] = React.useState([]);

  const fetchData = async () => {
    getSalePointsOfOrganization()
      .then((data) => {
        setSalePointListDefault(data["sale_points"]);
      })
      .catch((error) => {
        //setLoading(false);
        //setLoadingError(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(()=>{
    updateSalePoints(chipData);
  },[chipData, updateSalePoints]);

  const updateInputFranchise = async (input) => {
    setInputSalePoint(input);
    if(input.length === 0){
        setSalePointList([]);
        return;
    }
    const filtered = salePointListDefault.filter((franchise) => {
      return franchise.name.toLowerCase().includes(input.toLowerCase());
    });

    setSalePointList(filtered);
  };

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  const selectSalePoint = (salePoint) => () => {
    if(chipData.findIndex(s => s.key === salePoint.id) < 0){
      setChipData((chips) => [...chips, { key: salePoint.id, label: salePoint.name }]);
      console.log(chipData);
    }
    setInputSalePoint("");
    setSalePointList([]);
  };

  return (
        <div className='position-relative'>
            <div className="d-flex flex-wrap" style={{ overflow: 'hidden', maxWidth: '100%' }}>
            {chipData.map((data) => {
                let icon;

                return (
                <div className="m-1" key={data.key}>
                    <Chip
                    icon={icon}
                    label={data.label}
                    onDelete={handleDelete(data)}
                    />
                </div>
                );
            })}
            </div>
            {salePointListDefault && (
            <SearchBar
                input={inputSalePoint}
                onChange={updateInputFranchise}
                nombre="Puntos de venta"
            />
            )}
            <div className="position-absolute overflow-auto w-100 bg-white border" style={{'maxHeight':'150px'}}>
            {salePointList &&
                salePointList.map((salePoint, i) => (
                <div className="p-1" onClick={selectSalePoint(salePoint)} style={{'cursor':'pointer'}} key={salePoint.id}>{salePoint.name}</div>
                ))}
            </div>
        </div>
  );
}